import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "./Components/Toast";
import Router from "./Router/route";

function App() {
  useEffect(() => {
    document.title = "Occurlab";
  }, []);
  return (
    <>
      <div className="flex flex-col w-full h-screen bg-[#F7F8F9] font-medium text-[14px]">
        <ToastProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </ToastProvider>
      </div>
    </>
  );
}

export default App;
