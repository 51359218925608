export const LOGIN = "/";
export const EVENT_LIST = "/event";
export const EVENT_INFO = "/event-info";
export const DASHBOARD = "/dashboard";
export const GUEST_LIST = "/guest-list";
export const GUEST_BULK_PREVIEW = "/guest-bulk-preview";
export const GUEST_ADD = "/guest-add";
export const GUEST_EDIT = "/guest-edit";
export const MESSAGE_BLAST = "/message-blast";
export const MESSAGE_BLAST_ADD = "/message-blast-add";
export const MESSAGE_BLAST_EDIT = "/message-blast-edit";

export const CHECKIN = "/checkin";
export const EVENT_CHECK_IN_PAGE = "/event-check-in-page";

export const SEATING_CHART = "/seating-chart";

export const REPORT_OVERVIEW = "/report-overview";
export const SEATS_MANAGEMENT = "/seats-management";
export const SEATS_MANAGEMENT_EDIT = "/seats-management-edit";

export const DIETARY_MANAGEMENT = "/dietary-management";
export const DIETARY_EDIT = "/dietary-edit";

export const GUEST_LIST_IMPORT_LOG = "/guest-import-log";
export const GUEST_LIST_IMPORT_LOG_DETAILS = "/guest-import-log-details";
