import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import WidgetCard from "../../Components/WidgetCard";
import TextboxSet from "../../Components/TextboxSet";
import SeatManagementTable from "../../Components/Tables/SeatManagementTable";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import {
  CREATE_TABLE_API,
  GET_SEATS_WIDGET_API,
  GET_TABLE_DETAIL_LIST_API,
} from "../../Constant/api";
import Modal from "../../Components/Modal";
import Button from "../../Components/Button";
import Counter from "../../Components/Counter";

const SeatManagementList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableDetailData, setTableDetailData] = useState();
  const [tableDetailMeta, setTableDetailMeta] = useState();
  const [widgetData, setWidgetData] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    pax: 10,
  });
  const [showModal, setShowModal] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchCheckInList();
    }
  };

  const handlePageClick = (e) => {
    fetchCheckInList(e.selected + 1);
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchCheckInList(1, e.target.value);
  };

  const fetchWidgetData = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_SEATS_WIDGET_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
          },
        })
        .then((response) => {
          setLoading(false);
          setWidgetData(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to fetch widget data", "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to fetch widget data", "error");
    }
  };

  const fetchCheckInList = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_TABLE_DETAIL_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            search: searchTerm,
            page: page,
            perPage: perPageValue,
          },
        })
        .then((response) => {
          setLoading(false);
          setTableDetailData(response.data.data);
          setTableDetailMeta(response.data.meta.pagination);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to fetch check-in list", "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to fetch check-in list", "error");
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    try {
      axios
        .post(
          CREATE_TABLE_API,
          { ...formData, event_id: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          toggleModal();
          setLoading(false);
          fetchCheckInList();
          toast("Table created successfully", "success");
          setFormData({
            name: "",
            description: "",
            pax: 1,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to create table", "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to create table", "error");
    }
  };

  useEffect(() => {
    fetchWidgetData();
    fetchCheckInList();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {/* Create modal */}
      {showModal && (
        <Modal
          title="Add Table"
          toggleModal={toggleModal}
          handleSubmit={handleSubmit}
          content={
            <div className="flex flex-col w-full h-full gap-[24px]">
              <TextboxSet
                label="Table Name"
                name="name"
                type="text"
                required
                onChange={(e) => handleChange(e)}
              />
              <Counter formData={formData} setFormData={setFormData} />
              <TextboxSet
                label="Table Description"
                name="description"
                type="textarea"
                onChange={(e) => handleChange(e)}
              />
            </div>
          }
        />
      )}

      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Seats Management</h1>
              <Button onClick={toggleModal}>Add Table</Button>
            </div>

            {/* Widgets */}
            {widgetData && (
              <div className="flex w-full gap-[24px]">
                <WidgetCard
                  name="Total Tables"
                  data={widgetData.total_tables}
                />
                <WidgetCard name="Total Seats" data={widgetData.total_seats} />
                <WidgetCard
                  name="Seats Assigned"
                  data={widgetData.total_seats_assigned}
                />
              </div>
            )}

            {/* Table */}
            <div>
              <SeatManagementTable
                data={tableDetailData}
                meta={tableDetailMeta}
                searchTerm={searchTerm}
                handlePageClick={handlePageClick}
                handleSearch={handleSearch}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default SeatManagementList;
