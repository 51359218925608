import React, { useState } from "react";
import { useToast } from "./Toast";
import Dropzone from "react-dropzone";
import axios from "axios";
import { UPLOAD_TEMP_GUEST_LIST_API } from "../Constant/api";

const FileUpload = ({ handleChange }) => {
  const { toast } = useToast();

  const handleUploadFile = (acceptedFiles) => {
    const formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    axios
      .post(UPLOAD_TEMP_GUEST_LIST_API, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
        },
      })
      .then((res) => {
        handleChange(res.data.data.url);
        toast("File uploaded successfully", "success");
      })
      .catch((err) => {
        console.log(err);
        toast("Failed to upload file", "error");
      });
  };

  return (
    <Dropzone
      onDrop={handleUploadFile}
      accept={{
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "application/vnd.ms-excel.sheet.macroEnabled.12": [".xlsm"],
        "application/vnd.ms-excel.sheet.binary.macroEnabled.12": [".xlsb"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
          [".xltx"],
        "application/vnd.ms-excel.template.macroEnabled.12": [".xltm"],
        "text/csv": [".csv"],
      }}
      className="w-full h-full border-black"
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
      }) => {
        const additionalClass = isDragAccept
          ? "border-green-500"
          : isDragReject
          ? "border-red-500"
          : "border-[#EFF0F2]";

        return (
          <section className="flex w-full">
            <div
              {...getRootProps()}
              className={`flex flex-col border w-full h-[76px] justify-center items-center cursor-pointer rounded-lg ${additionalClass}`}
            >
              <input {...getInputProps()} />
              <p className="text-[#636C7E] font-normal">
                Drag & Drop your files or{" "}
                <span className="text-[#5C4BF5] font-medium">Browse</span>
              </p>
            </div>
          </section>
        );
      }}
    </Dropzone>
  );
};

export default FileUpload;
