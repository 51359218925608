import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2, Edit2, Message, Send2 } from "iconsax-react";
import { MESSAGE_BLAST_EDIT } from "../../Router/path";
import { useNavigate } from "react-router-dom";
import FilterTextInput from "../../Components/FilterTextInput";
import PerPageSelector from "../../Components/PerPageSelector";
import MessageBlastPopUp from "../../Components/MessageBlastPopUp";
import axios from "axios";
import {
  EMAIL_GUEST_OPTIONS_API,
  GET_GUEST_COUNT_API,
} from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import { LoadingOverlay } from "../../Components/LoadingOverlay";

const MessageBlastTable = ({
  data,
  meta,
  handlePageClick,
  currentTab,
  searchTerm,
  handleSearch,
  perPage,
  handlePerPageChange,
}) => {
  const [showSend, setShowSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guestCount, setGuestCount] = useState();
  const [guestOptions, setGuestOptions] = useState();
  const [templateToSend, setTemplateToSend] = useState();
  const navigate = useNavigate();
  const currentPage = meta.page > 0 ? meta.page - 1 : 0;

  const { toast } = useToast();

  const showSendPopUp = () => {
    setShowSend(true);
  };

  const togglePopUp = () => {
    setShowSend(!showSend);
  };

  const fetchGuestCount = () => {
    setLoading(true);
    try {
      axios
        .get(GET_GUEST_COUNT_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
          },
        })
        .then((res) => {
          setLoading(false);
          setGuestCount(res.data.data);
          fetchGuestOptions();
        })
        .catch((err) => {
          setLoading(false);
          toast("Failed to fetch guest count", "error");
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      toast("Failed to fetch guest count", "error");
      console.log(err);
    }
  };

  const fetchGuestOptions = () => {
    setLoading(true);
    try {
      axios
        .get(EMAIL_GUEST_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
          },
        })
        .then((res) => {
          setLoading(false);
          setGuestOptions(res.data.data);
          showSendPopUp();
        })
        .catch((err) => {
          setLoading(false);
          toast("Failed to fetch guest options", "error");
          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      toast("Failed to fetch guest options", "error");
      console.log(err);
    }
  };

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {loading && <LoadingOverlay />}

      {/* Send Email Pop Up */}
      {showSend && guestCount && guestOptions && !loading && (
        <MessageBlastPopUp
          togglePopUp={togglePopUp}
          guestCount={guestCount}
          guestOptions={guestOptions}
          templateId={templateToSend}
          setLoading={setLoading}
        />
      )}

      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput value={searchTerm} onChange={handleSearch} />
      </div>

      {/* Dashboard data */}
      {currentTab === "Email Template" && (
        <div className="flex w-full min-h-[290px] gap-[24px] no-scrollbar overflow-x-auto">
          {!data || data.length === 0 ? (
            <div className="flex w-full items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
              No email template
            </div>
          ) : (
            <table className="w-full h-full">
              {/* Table Header */}
              <tr className="bg-[#F7F8F9]">
                <th className="text-start px-[24px] py-[16px]">
                  Email Template Name
                </th>
                <th className="text-start px-[24px] py-[16px]">
                  Email Subject
                </th>
                <th className="text-start px-[24px] py-[16px]"></th>
              </tr>

              {/* Table Body */}
              {data &&
                data.map((item) => (
                  <tr
                    key={item.id}
                    className="font-normal border-y border-[#EFF0F2]"
                  >
                    <td className="px-[24px] py-[16px]">{item.name ?? "-"}</td>
                    <td className="px-[24px] py-[16px]">
                      {item.subject ?? "-"}
                    </td>
                    <td className="flex px-[24px] py-[16px] gap-[12px] justify-end">
                      <span
                        className="flex text-[#636C7E] text-[14px] items-center hover:underline cursor-pointer"
                        onClick={() => {
                          setTemplateToSend(item.id);
                          fetchGuestCount();
                        }}
                      >
                        <Send2 size="15" variant="Bold" />
                        Send
                      </span>
                      <span
                        className="flex text-[#5C4BF5] text-[14px] items-center hover:underline cursor-pointer"
                        onClick={() =>
                          navigate(MESSAGE_BLAST_EDIT, {
                            state: { recordId: item.id },
                          })
                        }
                      >
                        <Edit2 size="15" variant="Bold" />
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
            </table>
          )}
        </div>
      )}

      {/* Pagination */}
      {meta && (
        <div className="grid grid-cols-3 w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center justify-between font-normal">
          {/* Page number and total */}
          <div className="grid w-full">
            Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
          </div>

          {/* Number per page selector */}
          <div className="grid w-full justify-center">
            <PerPageSelector
              perPage={perPage}
              handlePerPageChange={handlePerPageChange}
            />
          </div>

          {/* Page selector */}
          <div className="grid w-full justify-end">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={meta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageBlastTable;
