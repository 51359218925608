import React, { useState } from "react";
import Button from "../../Components/Button";
import EmailBlastForm from "../../Components/EmailBlastForm";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useToast } from "../../Components/Toast";
import { CREATE_EMAIL_TEMPLATE_API } from "../../Constant/api";
import LoadingInComponent from "../../Components/LoadingInComponent";
import { MESSAGE_BLAST } from "../../Router/path";
import EmailGuide from "../../Components/EmailGuide";

const MessageBlastAdd = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    from_email: "",
    from_name: "",
    subject: "",
    preheader: "",
    content:
      "<p>Dear ##guest_name##</p><p>We are thrilled to remind you that our wedding day is just one week away! Here are the details to ensure you have everything you need for the big day:</p><p><br></p><p><strong>Date:</strong> ##event_date##</p><p><strong>Time:</strong> ##event_time##</p><p><strong>Venue:</strong> ##event_venue##</p><p><strong>Reception:</strong> [Reception Venue Name and Address (if different)]</p><p><br></p><p>Schedule:</p><p>Ceremony: [Ceremony Time and Brief Description]</p><p>Reception: [Reception Time and Brief Description]</p><p>Other Activities: [Any additional activities or after-party details]</p><p><br></p><p>We are so excited to celebrate this special day with you and look forward to seeing you soon. If you have any questions or need further information, please do not hesitate to contact us at [Contact Information]</p><p>[Bride's Name] &amp; [Groom's Name]</p><p>[Contact Information]</p>",
    type: "",
  });

  const location = useLocation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const messageType = location.state?.type;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreate = () => {
    setLoading(true);
    var type = "";
    if (messageType === "Email Template") {
      // setFormData({ ...formData, type: "email" });
      type = "email";
    } else if (messageType === "WhatsApp Template") {
      // setFormData({ ...formData, type: "whatsapp" });
      type = "whatsapp";
    }

    try {
      axios
        .post(
          CREATE_EMAIL_TEMPLATE_API,
          { ...formData, type: type },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
            params: { eventId: localStorage.getItem("event_id") },
          }
        )
        .then((response) => {
          setLoading(false);
          navigate(MESSAGE_BLAST, { state: { type: messageType } });
          toast("Email template created successfully", "success");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to create email template", "error");
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Create Template</h1>
              {/* <Button variant="secondary">Preview</Button> */}
            </div>

            {/* Guide */}
            <EmailGuide />

            {/* Form */}
            <div className="w-full">
              <EmailBlastForm formData={formData} handleChange={handleChange} />
            </div>

            {/* Button */}
            <div className="flex w-full gap-[8px] items-center">
              <Button variant="primary" onClick={handleCreate}>
                Create
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  navigate(MESSAGE_BLAST, { state: { type: messageType } })
                }
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default MessageBlastAdd;
