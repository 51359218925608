import React from "react";

const PerPageSelector = ({ handlePerPageChange, perPage }) => {
  return (
    <div className="flex border w-fit border-[#E0E2E5] rounded-xl">
      <h2 className="text-[#C1C4CB] py-[8px] px-[12px] border-r border-[#E0E2E5]">
        Per Page
      </h2>
      <select
        className="w-[56px] rounded-xl focus:outline-none mx-[10px]"
        name="perPage"
        id="perPage"
        onChange={handlePerPageChange}
        value={perPage}
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="40">40</option>
        <option value="50">50</option>
      </select>
    </div>
  );
};

export default PerPageSelector;
