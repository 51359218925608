import { SearchNormal1 } from "iconsax-react";
import React from "react";

const FilterTextInput = ({ onChange, placeholder, className, value }) => {
  return (
    <div
      className={`${className} flex px-[12px] py-[11px] items-center gap-[8px] rounded-xl bg-white border border-[#EFF0F2] text-[14px]`}
    >
      <SearchNormal1 size="16" color="#C1C4CB" />
      <input
        type="text"
        placeholder={placeholder ?? "Search"}
        className="w-full font-normal outline-none"
        value={value}
        onChange={onChange}
        onKeyDown={onChange}
      />
    </div>
  );
};

export default FilterTextInput;
