import React from "react";
import SectionCard from "./SectionCard";

const EmailGuide = () => {
  const tagList = [
    {
      tag: "##guest_name##",
      data: "Guest's name, will add salutation if available.",
    },
    {
      tag: "##event_name##",
      data: "Event's name.",
    },
    {
      tag: "##event_date##",
      data: "Event's date.",
    },
    {
      tag: "##event_time##",
      data: "Event's time.",
    },
    {
      tag: "##event_venue##",
      data: "Event's venue.",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <SectionCard
        title="Content Guides"
        description="Adding the tags will allow the email to automatically replace them with keyed in data."
        collapsible
        defaultUncollapsedState={false}
        content={
          <table className="border border-[#EFF0F2] border-collapse">
            <tr>
              <th className="border border-[#EFF0F2] p-[8px]">Tags</th>
              <th className="border border-[#EFF0F2] p-[8px]">Data</th>
            </tr>
            {tagList.map((tag) => (
              <tr className="border border-[#EFF0F2] font-normal">
                <td className="border border-[#EFF0F2] p-[8px]">{tag.tag}</td>
                <td className="border border-[#EFF0F2] p-[8px]">{tag.data}</td>
              </tr>
            ))}
          </table>
        }
      />
    </div>
  );
};

export default EmailGuide;
