import { TickCircle } from "iconsax-react";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const DraggableGuestCard = ({ index, data }) => {
  return (
    <Draggable
      draggableId={`${data.guest_id}`}
      key={data.guest_id}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className="flex w-full p-[16px] items-center justify-between font-normal text-[14px] bg-white hover:bg-[#F7F8FA] rounded-xl"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="flex w-full font-normal gap-[8px] items-center">
            {data.table_id && <h2>{index + 1}.</h2>}
            <div className="flex flex-col w-full">
              <h2>
                {data.salutation && `${data.salutation}. `}
                {data.guest_name}
              </h2>
              {data.replaced_by && (
                <div className="flex gap-[5px]">
                  <h2>
                    <span className="text-purple-500">replaced by</span>{" "}
                    {data.replaced_by}
                  </h2>
                </div>
              )}
              {data.email && <p className="text-[#636C7E]">{data.email}</p>}
              {data.contact && <p className="text-[#1c1e20]">{data.contact}</p>}
            </div>
          </div>
          {data.status && (
            <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#F4FCF7] border border-[#E9F9EF] text-[#22C55E] text-[11px]">
              <TickCircle size="11" variant="Bold" />
              Checked
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableGuestCard;
