import React from "react";
import FilterTextInput from "../FilterTextInput";
import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import PerPageSelector from "../PerPageSelector";
import { useNavigate } from "react-router-dom";
import { GUEST_LIST_IMPORT_LOG_DETAILS } from "../../Router/path";

const ImportLogTable = ({
  type,
  data,
  meta,
  handlePageClick,
  searchTerm,
  handleSearch,
  perPage,
  handlePerPageChange,
}) => {
  const currentPage = meta ? (meta.page > 0 ? meta.page - 1 : 0) : null;

  const navigate = useNavigate();

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput value={searchTerm} onChange={handleSearch} />
      </div>

      {/* Table data */}
      {type === "list" ? (
        <div className="flex w-full h-full gap-[24px] overflow-x-auto">
          {!data || data.length === 0 ? (
            <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
              No imports entry
            </div>
          ) : (
            <table className="w-full h-full">
              {/* Table Header */}
              <tr className="w-full bg-[#F7F8F9] border-b border-[#EFF0F2]">
                <th className="text-start px-[24px] py-[16px]">File Name</th>
                <th className="text-start px-[24px] py-[16px]">Total Rows</th>
                <th className="text-start px-[24px] py-[16px]">
                  Successful Rows
                </th>
                <th className="text-start px-[24px] py-[16px]">Skipped Rows</th>
                <th className="text-start px-[24px] py-[16px]">Created At</th>
              </tr>

              {/* Table Body */}
              {data &&
                data.map((item) => (
                  <tr
                    key={item.id}
                    className="w-full font-normal border-b border-[#EFF0F2] last:border-none hover:bg-[#F7F8F9] cursor-pointer"
                    onClick={() => {
                      navigate(GUEST_LIST_IMPORT_LOG_DETAILS, {
                        state: { importId: item.id, fileName: item.file_name },
                      });
                    }}
                  >
                    <td className="px-[24px] py-[16px]">{item.file_name}</td>
                    <td className="px-[24px] py-[16px]">{item.total_rows}</td>
                    <td className="px-[24px] py-[16px]">
                      {item.successful_rows}
                    </td>
                    <td className="px-[24px] py-[16px]">{item.issues}</td>
                    <td className="px-[24px] py-[16px]">{item.created_at}</td>
                  </tr>
                ))}
            </table>
          )}
        </div>
      ) : (
        type === "details" && (
          <div className="flex w-full h-full gap-[24px] overflow-x-auto">
            {!data || data.length === 0 ? (
              <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
                No import detail entry
              </div>
            ) : (
              <table className="w-full h-full">
                {/* Table Header */}
                <tr className="w-full bg-[#F7F8F9] border-b border-[#EFF0F2]">
                  <th className="text-start px-[24px] py-[16px]">Row</th>
                  <th className="text-start px-[24px] py-[16px]">
                    Reason/Error
                  </th>
                </tr>

                {/* Table Body */}
                {data &&
                  data.map((item) => (
                    <tr
                      key={item.id}
                      className="w-full font-normal border-b border-[#EFF0F2] last:border-none"
                    >
                      <td className="px-[24px] py-[16px]">{item.row_number}</td>
                      <td className="px-[24px] py-[16px]">
                        {item.validation_error}
                      </td>
                    </tr>
                  ))}
              </table>
            )}
          </div>
        )
      )}

      {/* Pagination */}
      {meta && (
        <div className="grid grid-cols-3 w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center font-normal">
          {/* Page number and total */}
          <div className="grid w-full">
            Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
          </div>

          {/* Number per page selector */}
          <div className="grid w-full justify-center">
            <PerPageSelector
              handlePerPageChange={handlePerPageChange}
              perPage={perPage}
            />
          </div>

          {/* Page selector */}
          <div className="grid w-full justify-end">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={meta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportLogTable;
