import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { useToast } from "./Toast";

const LocationSet = ({
  name,
  placeholder,
  value,
  disabled,
  required,
  onChange,
  readOnly,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);

  const { toast } = useToast();
  const debounceTimeout = useRef(null);

  const handleInputChange = async (e) => {
    setInputValue(e.target.value);
    onChange(e);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchSuggestions(e.target.value);
    }, 500);
  };

  const fetchSuggestions = async (query) => {
    if (query.length > 2) {
      try {
        await axios
          .get(`https://nominatim.openstreetmap.org/search`, {
            params: {
              q: query,
              format: "json",
              addressdetails: 1,
              limit: 5,
            },
          })
          .then((response) => {
            setSuggestions(response.data);
          })
          .catch((error) => {
            setSuggestions([]);
            toast("Failed to fetch suggestions", "error");
          });
      } catch (error) {
        toast("Failed to fetch suggestions", "error");
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const placeName = suggestion.display_name;
    onChange({
      target: { name, value: placeName },
    });
    setSuggestions([]);
    setInputValue(placeName);
  };

  useEffect(() => {
    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, []);

  return (
    <div className="flex flex-col w-full gap-[6px]">
      <h2
        className={`flex focused:text-[#365BC9] text-[14px] ${
          disabled ? "text-[#D0D3D8]" : ""
        }`}
      >
        Venue {required && <span className="text-red-500">*</span>}
      </h2>
      <div className="relative">
        <input
          type="text"
          name={name}
          className="border border-[#EFF0F2] rounded-lg hover:border-black focus:border-[#5C4BF5] focus:outline-[#5C4BF5] focus:shadow-[0px_0px_4px_0px_#6200EE80] disabled:border-[#D0D3D8] disabled:hover:border-[#D0D3D8] disabled:placeholder:text-[#D0D3D8] font-normal py-[8px] px-[16px] w-full pr-[40px]"
          placeholder={disabled ? "Disabled" : placeholder}
          value={inputValue}
          onChange={handleInputChange}
          disabled={disabled}
          readOnly={readOnly}
        />
        {suggestions.length > 0 && (
          <div className="absolute z-10 w-full bg-white border border-[#EFF0F2] mt-1 rounded-lg shadow-lg max-h-48 overflow-y-auto">
            {suggestions.map((suggestion) => (
              <div
                key={suggestion.place_id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-200 border-b last:border-none"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.display_name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationSet;
