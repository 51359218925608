import React from "react";
import SectionCard from "../SectionCard";
import { ExportCircle, ImportCircle } from "iconsax-react";
import Button from "../Button";

const GroupMemberListing = ({ members, handleCheckIn, handleCheckOut }) => {
  return (
    <SectionCard
      title="Group Members"
      collapsible
      content={
        <div className="flex flex-col w-full gap-[24px]">
          {members && members.length > 0 ? (
            <div className="flex flex-col w-full h-full gap-[24px]">
              <div className="flex w-full gap-[16px]">
                <Button
                  className="w-full"
                  onClick={(type) => handleCheckIn({ type: "all" })}
                >
                  <ImportCircle size="16" variant="Outline" />
                  Check In All
                </Button>
                <Button
                  className="w-full"
                  variant="error"
                  onClick={() => handleCheckOut({ type: "all" })}
                >
                  <ExportCircle size="16" variant="Outline" />
                  Check Out All
                </Button>
              </div>
              {members.map((member, index) => (
                <div
                  className="flex w-full gap-[12px] items-center justify-between"
                  key={index}
                >
                  <div className="w-fit py-[9px] px-[10px] text-[20px] border border-[#EFF0F2] rounded-xl text-nowrap">
                    {member.table_name}
                  </div>
                  <div className="flex flex-col w-full text-[14px] font-normal">
                    <p>{`${member.salutation}. ${member.name}`}</p>
                    <div>
                      <p className="text-[12px] text-[#636C7E]">
                        {member.email && member.email}
                        {member.email && member.contact && " / "}
                        {member.contact && member.contact}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end w-fit">
                    {member.status ? (
                      <Button
                        variant="error"
                        className="h-full"
                        onClick={() => handleCheckOut({ id: member.id })}
                      >
                        <ExportCircle size="16" variant="Outline" />
                      </Button>
                    ) : (
                      <Button
                        className="h-full"
                        onClick={() => handleCheckIn({ id: member.id })}
                      >
                        <ImportCircle size="16" variant="Outline" />
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex w-full items-center justify-center text-gray-400">
              <h1>No group members</h1>
            </div>
          )}
        </div>
      }
    />
  );
};

export default GroupMemberListing;
