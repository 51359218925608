import { TickCircle } from "iconsax-react";
import React from "react";

const GuestCard = ({ data, onClick }) => {
  return (
    <div
      className="flex w-full p-[16px] items-center justify-between font-normal text-[14px] bg-white hover:bg-[#F7F8FA] hover:cursor-pointer rounded-xl"
      onClick={onClick}
    >
      <div className="flex font-normal gap-[8px] items-center">
        <div className="flex flex-col w-full">
          <h2>
            {data.salutation && `${data.salutation}. `}
            {data.name}
          </h2>
          <div className="flex w-full gap-[4px]">
            {data.email && <p className="text-[#636C7E]">{data.email}</p>}
            {data.email && data.contact && <span> / </span>}
            {data.contact && <p className="text-[#636C7E]">{data.contact}</p>}
          </div>
        </div>
      </div>
      {data.status && (
        <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#F4FCF7] border border-[#E9F9EF] text-[#22C55E] text-[11px]">
          <TickCircle size="11" variant="Bold" />
          Checked
        </div>
      )}
    </div>
  );
};

export default GuestCard;
