import React from "react";

const Button = ({
  type,
  children,
  variant = "primary",
  className,
  onClick,
  disabled,
  tabNumber,
}) => {
  const variants = {
    primary:
      "py-[10px] px-[16px] justify-center bg-[#5C4BF5] text-white hover:bg-[#7D6FF7] active:bg-[#4A3CC9] disabled:bg-[#E0E2E5] disabled:text-[#A1A7B2]",
    secondary:
      "py-[10px] px-[16px] justify-center border border-[#EFF0F2] bg-white text-black hover:bg-[#F7F8F9] active:bg-[#EFF0F2] disabled:border-[#D0D3D8] disabled:text-[#A1A7B2]",
    tertiary:
      "py-[10px] px-[16px] justify-center bg-transparent text-black hover:text-[#5C4BF5] active:text-[#4A3CC9] disabled:text-[#A1A7B2]",
    fourth:
      "py-[10px] px-[16px] justify-center border border-[#EFF0F2] bg-[#514539] text-white hover:bg-[#453B30] active:bg-[#3D352D] disabled:border-[#D0D3D8] disabled:text-[#A1A7B2]",

    error:
      "py-[10px] px-[16px] justify-center bg-red-500 text-white hover:bg-red-400 active:bg-[#4A3CC9] disabled:bg-[#E0E2E5] disabled:text-[#A1A7B2]",

    sidebar:
      "py-[10px] px-[8px] bg-transparent justify-start text-[#595F72] hover:bg-gray-300",
    sidebar_grouping:
      "py-[10px] px-[8px] bg-transparent justify-start text-black justify-between",
    sidebar_active:
      "py-[10px] px-[8px] bg-[#EFEDFE] justify-start text-[#5C4BF5]",

    icon: "p-[8px] border border-[#E2E4E8] justify-center bg-transparent rounded-lg",

    tab_button_default:
      "py-[8px] px-[12px] text-[14px] justify-center text-[#636C7E] rounded-xl hover:text-black hover:bg-[#F7F8F9]",
    tab_button_selected:
      "py-[8px] px-[12px] text-[14px] justify-center rounded-xl bg-[#F7F8F9] text-[#5C4BF5]",
  };

  const classes = `flex items-center ${variants[variant]} ${className} rounded-lg gap-[8px] text-[14px]`;

  return (
    <button
      className={classes}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
      {tabNumber !== null &&
        (variant === "tab_button_default" ||
          variant === "tab_button_selected") && (
          <span className="border border-[#DEDBFD] bg-[#EFEDFE] rounded-lg py-[2px] px-[6px]">
            {tabNumber}
          </span>
        )}
    </button>
  );
};

export default Button;
