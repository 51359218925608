import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import Button from "../../Components/Button";
import WidgetCard from "../../Components/WidgetCard";
import ApexCharts from "react-apexcharts";
import axios from "axios";
import {
  CREATE_DIETARY_API,
  EXPORT_DIETARY_REPORT_API,
  GET_DIETARY_LIST_API,
  GET_DIETARY_WIDGET_API,
} from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import Modal from "../../Components/Modal";
import TextboxSet from "../../Components/TextboxSet";
import DietaryTable from "../../Components/Tables/DietaryTable";
import fileDownload from "js-file-download";

const DietaryList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [widgetData, setWidgetData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [meta, setMeta] = useState({});
  const [dietaryName, setDietaryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "pie",
    },
    labels: [],
  });
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handlePageClick = (e) => {
    fetchDietaryList(e.selected + 1);
  };

  const handleOnChange = (e) => {
    setDietaryName(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchDietaryList();
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchDietaryList(1, e.target.value);
  };

  const handleAddDietary = () => {
    try {
      axios
        .post(
          CREATE_DIETARY_API,
          {
            name: dietaryName,
            event_id: localStorage.getItem("event_id"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          toast("Dietary added successfully", "success");
          toggleModal();
          setDietaryName("");
          fetchDietaryList();
          fetchWidgetData();
        })
        .catch((error) => {
          toast("Failed to add dietary", "error");
          console.error(error);
        });
    } catch (error) {
      toast("Failed to add dietary", "error");
      console.error(error);
    }
  };

  const fetchWidgetData = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_DIETARY_WIDGET_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
          },
        })
        .then((response) => {
          setLoading(false);
          setWidgetData(response.data.data);
          setChartSeries(response.data.data.dietary_breakdown.data);
          setChartOptions({
            ...chartOptions,
            labels: response.data.data.dietary_breakdown.labels,
          });
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch widget data", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch widget data", "error");
      console.error(error);
    }
  };

  const fetchDietaryList = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_DIETARY_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            page: page,
            search: searchTerm,
            perPage: perPageValue,
          },
        })
        .then((response) => {
          setLoading(false);
          setTableData(response.data.data);
          setMeta(response.data.meta.pagination);
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch dietary list", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch dietary list", "error");
      console.error(error);
    }
  };

  const exportDietaryReport = async () => {
    try {
      axios
        .post(
          EXPORT_DIETARY_REPORT_API,
          { eventId: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
              TimezoneOffset: new Date().getTimezoneOffset(),
            },
          }
        )
        .then((res) => {
          fileDownload(res.data, "dietary_report.csv");
        })
        .catch((err) => {
          toast("Failed to export.", "error");
          console.log(err);
        });
    } catch (error) {
      toast("Failed to export.", "error");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWidgetData();
    fetchDietaryList();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {showModal && (
        <Modal
          title="Add Dietary"
          handleSubmit={handleAddDietary}
          submitLabel="Add Dietary"
          toggleModal={toggleModal}
          content={
            <div className="w-full">
              <TextboxSet
                label="Dietary Name"
                name="dietary_name"
                value={dietaryName}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
          }
        />
      )}
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title */}
            <div className="flex justify-between items-center">
              <h1 className="text-[29px]">Special Dietary Management</h1>
              <div className="flex gap-[8px]">
                <Button variant="secondary" onClick={exportDietaryReport}>
                  Export Report
                </Button>
                <Button onClick={toggleModal}>Add Dietary</Button>
              </div>
            </div>

            {/* Widgets */}
            <div className="flex w-full h-fit gap-[24px]">
              <div className="flex flex-col w-full gap-[24px]">
                <WidgetCard
                  name="Total Dietary"
                  data={widgetData.total_dietary}
                />
                <WidgetCard
                  name="Total Requests"
                  data={widgetData.total_requests}
                />
              </div>
              <div className="flex flex-col border border-[#EFF0F2] w-full h-full max-h-[301px] p-[24px] gap-[12px] bg-white rounded-xl">
                <h2 className="text-[14px]">Dietary Requests Breakdown</h2>
                {chartSeries && chartSeries.length === 0 ? (
                  <div className="flex w-full h-[100vh] items-center justify-center">
                    <p className=" text-gray-600">No data available</p>
                  </div>
                ) : (
                  <div>
                    <ApexCharts
                      options={chartOptions}
                      series={chartSeries}
                      type="pie"
                      width="350"
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Data */}
            <div className="w-full">
              <DietaryTable
                data={tableData}
                meta={meta}
                handlePageClick={handlePageClick}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default DietaryList;
