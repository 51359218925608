import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import Button from "../../Components/Button";
import { Add, ExportSquare, ImportCurve, ImportSquare } from "iconsax-react";
import GuestListTable from "../../Components/Tables/GuestListTable";
import axios from "axios";
import { useToast } from "../../Components/Toast";
import {
  EXPORT_GUEST_API,
  EXPORT_GUEST_LIST_TEMPLATE,
  GUEST_LIST_API,
} from "../../Constant/api";
import Modal from "../../Components/Modal";
import { useNavigate } from "react-router-dom";
import { GUEST_ADD, GUEST_BULK_PREVIEW } from "../../Router/path";
import FileUpload from "../../Components/FileUpload";
import fileDownload from "js-file-download";

const GuestListPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState();
  const [guestListMeta, setGuestListMeta] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchGuestList();
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchGuestList(1, e.target.value);
  };

  const handleFileUpload = (url) => {
    navigate(GUEST_BULK_PREVIEW, { state: { url: url } });
  };

  const handlePageClick = (e) => {
    fetchGuestList(e.selected + 1);
  };

  const fetchGuestList = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GUEST_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            page: page,
            perPage: perPageValue,
            search: searchTerm,
          },
        })
        .then((res) => {
          setLoading(false);
          setGuestList(res.data.data);
          setGuestListMeta(res.data.meta.pagination);
        })
        .catch((err) => {
          setLoading(false);
          toast("Failed to fetch guest list.", "error");
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch guest list.", "error");
      console.log(error);
    }
  };

  const downloadImportTemplate = async () => {
    try {
      axios
        .get(EXPORT_GUEST_LIST_TEMPLATE, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
        })
        .then((res) => {
          fileDownload(res.data, "guest_list_template.csv");
        })
        .catch((err) => {
          toast("Failed to fetch guest list template.", "error");
          console.log(err);
        });
    } catch (error) {
      toast("Failed to fetch guest list template.", "error");
      console.log(error);
    }
  };

  const exportGuestList = async () => {
    try {
      axios
        .post(
          EXPORT_GUEST_API,
          { eventId: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
              TimezoneOffset: new Date().getTimezoneOffset(),
            },
          }
        )
        .then((res) => {
          fileDownload(res.data, "guest_list.csv");
        })
        .catch((err) => {
          toast("Failed to fetch guest list template.", "error");
          console.log(err);
        });
    } catch (error) {
      toast("Failed to fetch guest list template.", "error");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchGuestList();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {showModal && (
        <Modal
          title="Import Guest List"
          description="Import a .xlsx or .csv format file."
          content={<FileUpload handleChange={handleFileUpload} />}
          toggleModal={toggleModal}
        />
      )}
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Guest List</h1>
              <div className="flex w-fit items-center gap-[8px]">
                <Button variant="secondary" onClick={exportGuestList}>
                  <ExportSquare size="16" variant="Outline" /> Export Guest List
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate(GUEST_ADD);
                  }}
                >
                  <Add size={16} variant="Outline" /> Add Guest
                </Button>
              </div>
            </div>

            {/* Bulk upload area */}
            <div className="flex flex-col w-full h-fit bg-white rounded-xl border border-[#EFF0F2]">
              <div className="flex w-full items-center justify-between px-[24px] py-[16px] border-b border-[#EFF0F2] text-[16px]">
                <h2>Import Guest List</h2>
              </div>
              <div className="flex flex-col p-[24px] gap-[24px] font-normal">
                <p>
                  Easily manage your event by importing your guest list directly
                  from an Excel file. Follow the steps below to download the
                  template and upload your completed guest list:
                </p>
                <ol
                  style={{
                    listStyleType: "decimal",
                    listStylePosition: "inside",
                  }}
                  className="border border-[#EFF0F2] rounded-xl"
                >
                  <li className="p-[16px] border-b border-[#EFF0F2] last:border-none">
                    First,{" "}
                    <span className="font-bold">
                      Download the Excel template provided,{" "}
                    </span>
                    and fill in all necessary guest details, including names,
                    contact information, table assignments, and any special
                    dietary requirements.
                  </li>
                  <li className="p-[16px] border-b border-[#EFF0F2] last:border-none">
                    Ensuring that all information is accurate and correctly
                    formatted,{" "}
                    <span className="font-bold">
                      then save your completed template.
                    </span>
                  </li>
                  <li className="p-[16px] last:border-none">
                    Import the file by{" "}
                    <span className="font-bold">
                      selecting the Import Guest button,{" "}
                    </span>
                    uploading the Excel file, and reviewing the imported data
                    for any discrepancies.
                  </li>
                </ol>
                {/* <p>
                  For more details and steps, please read our{" "}
                  <a href="#" className="underline">
                    import guides
                  </a>
                  .
                </p> */}
                <div className="flex gap-[8px]">
                  <Button variant="primary" onClick={toggleModal}>
                    <ImportSquare size="16" />
                    Import Guest List
                  </Button>
                  <Button variant="secondary" onClick={downloadImportTemplate}>
                    <ImportCurve size="16" />
                    Download Excel Template
                  </Button>
                </div>
              </div>
            </div>

            {/* Guest list table */}
            <div className="w-full">
              <GuestListTable
                searchable
                editable
                data={guestList}
                meta={guestListMeta}
                handlePageClick={handlePageClick}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                handlePerPageChange={handlePerPageChange}
                perPage={perPage}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default GuestListPage;
