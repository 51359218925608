import React from "react";

const TimestampCard = ({ created_at, updated_at }) => {
  const handleDays = (days) => {
    if (days === 0) {
      return "Today";
    } else if (days > 0 && days < 7) {
      return `${days} days ago`;
    } else if (days >= 7 && days < 30) {
      return `${Math.floor(days / 7)} weeks ago`;
    } else if (days >= 30 && days < 365) {
      return `${Math.floor(days / 30)} months ago`;
    } else {
      return `${Math.floor(days / 365)} years ago`;
    }
  };

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex flex-col gap-[8px]">
        <h3 className="text-[14px]">Created At</h3>
        <p className="text-[14px] text-[#636C7E]">{handleDays(created_at)}</p>
      </div>
      <div className="flex flex-col gap-[8px]">
        <h3 className="text-[14px]">Last Modified At</h3>
        <p className="text-[14px] text-[#636C7E]">{handleDays(updated_at)}</p>
      </div>
    </div>
  );
};

export default TimestampCard;
