import React, { useState, useEffect } from "react";
import Button from "../../Components/Button";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import FilterTextInput from "../../Components/FilterTextInput";
import NormalMode from "./NormalMode";
import SwapMode from "./SwapMode";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import {
  GET_SEATING_HEADER_API,
  GET_TABLES_GUESTS_API,
  GET_UNSEATED_GUESTS_API,
  UPDATE_GUESTS_SEATS_API,
} from "../../Constant/api";
import { useNavigate } from "react-router-dom";
import { SEATS_MANAGEMENT } from "../../Router/path";
import DraggableGuestCard from "../../Components/SeatingChart/DraggableGuestCard";
import LoadingInComponent from "../../Components/LoadingInComponent";

const SeatingChart = () => {
  const [mode, setMode] = useState("normal");
  const [loading, setLoading] = useState(false);
  const [headerData, setHeaderData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [seatedData, setSeatedData] = useState([]);
  const [unseatedData, setUnseatedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const { toast } = useToast();
  const navigate = useNavigate();

  const fetchHeaderData = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_SEATING_HEADER_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setLoading(false);
          setHeaderData(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch header data.", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch header data.", "error");
      console.error(error);
    }
  };

  const fetchTableData = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_TABLES_GUESTS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setLoading(false);
          setSeatedData(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch table data.", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch table data.", "error");
      console.error(error);
    }
  };

  const fetchUnseatedGuests = async (reset = false) => {
    setLoading(true);
    try {
      axios
        .get(GET_UNSEATED_GUESTS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            search: searchTerm,
            exclude_ids: reset
              ? []
              : seatedData
                  .map((table) => table.guest_list)
                  .flat()
                  .map((guest) => guest.guest_id),
          },
        })
        .then((response) => {
          setLoading(false);
          setUnseatedData(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch unseated guests.", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch unseated guests.", "error");
      console.error(error);
    }
  };

  const submitUpdatedTableData = async () => {
    setLoading(true);
    try {
      axios
        .put(UPDATE_GUESTS_SEATS_API, tableData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
        })
        .then((response) => {
          handleRefresh();
          setLoading(false);
          toast("Updated table data successfully", "success");
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to update table data", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to update table data", "error");
      console.error(error);
    }
  };

  const handleRefresh = () => {
    fetchHeaderData();
    fetchTableData();
    fetchUnseatedGuests(true);
  };

  const handleDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const movedData = tableData
      .find((table) => table.table_id === parseInt(source.droppableId))
      .guest_list.find((g) => g.guest_id === parseInt(draggableId));

    var newTableData = [...tableData];

    const sourceTable = newTableData.find(
      (table) => table.table_id === parseInt(source.droppableId)
    );

    const destinationTable = newTableData.find(
      (table) => table.table_id === parseInt(destination.droppableId)
    );

    if (
      destinationTable.guest_list.length >= destinationTable.pax &&
      destinationTable.pax !== null
    ) {
      return toast("Table is full", "error");
    } else {
      if (destination.droppableId === "-2") {
        movedData.table_id = null;
      } else {
        movedData.table_id = parseInt(destination.droppableId);
      }
      sourceTable.guest_list.splice(source.index, 1);
      destinationTable.guest_list.splice(destination.index, 0, movedData);
    }

    setTableData(newTableData);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchUnseatedGuests();
    }
  };

  useEffect(() => {
    fetchHeaderData();
    fetchTableData();
    fetchUnseatedGuests();
  }, []);

  useEffect(() => {
    setTableData([...seatedData, ...unseatedData]);
  }, [seatedData, unseatedData]);

  return (
    <div className="flex flex-col w-full h-[100vh] bg-[#F7F8F9]">
      <LoadingInComponent isLoading={loading}>
        {/* Header */}
        <div className="sticky grid grid-cols-3 w-full p-[24px] h-full max-h-[80px] border border-[#EFF0F2] bg-white items-center justify-between">
          <h1 className="grid text-[20px]">Seating Chart</h1>

          <div className="flex justify-center items-center w-full">
            {headerData && Object.keys(headerData).length > 0 && (
              <div className="flex items-center justify-center gap-[24px]">
                <div className="flex flex-col items-center justify-center">
                  <p className="font-normal text-[#636C7E] text-[12px]">
                    Total Tables
                  </p>
                  <h2 className="font-semibold text-14px">
                    {headerData.total_tables}
                  </h2>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-normal text-[#636C7E] text-[12px]">
                    Total Seats
                  </p>
                  <h2 className="font-semibold text-14px">
                    {headerData.total_seats}
                  </h2>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-normal text-[#636C7E] text-[12px]">
                    Seats Assigned
                  </p>
                  <h2 className="font-semibold text-14px">
                    {headerData.total_seats_assigned}
                  </h2>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <p className="font-normal text-[#636C7E] text-[12px]">
                    Checked
                  </p>
                  <h2 className="font-semibold text-14px">
                    {headerData.checked}
                  </h2>
                </div>
              </div>
            )}
          </div>

          <div className="grid w-full items-center justify-end">
            <div className="flex items-center gap-[8px]">
              <Button onClick={submitUpdatedTableData}>Save</Button>
              <Button
                variant="secondary"
                onClick={() => navigate(SEATS_MANAGEMENT)}
              >
                Close
              </Button>
            </div>
          </div>
        </div>

        {/* Main */}
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="flex w-full">
            {/* Sidebar */}
            <div className="flex flex-col w-full h-[calc(100vh-80px)] max-w-[250px] xl:max-w-[400px] bg-white border-r border-[#EFF0F2] no-scrollbar overflow-y-auto">
              {/* Top part */}
              <div className="flex flex-col w-full p-[12px] xl:p-[24px] border-b border-[#EFF0F2] gap-[24px]">
                <div className="flex flex-col w-full text-[14px]">
                  <h2>Guest List</h2>
                  <p className="font-normal text-[#636C7E]">
                    You can assign seat by drag and drop, or search in the table
                    chart. Able to multi-select drag and swapping seats.
                  </p>
                </div>
                <FilterTextInput
                  placeholder="Search Guest"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>

              {/* Bottom part */}
              <div className="flex flex-col w-full p-[24px] border-t border-[#becbe4] gap-[16px]">
                <h2>Unseated Guests</h2>
                <Droppable droppableId="-2" index={tableData.length - 1}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="border-l border-gray-300 h-fit min-h-[56px]"
                    >
                      {tableData
                        .filter((table) => table.table_id === -2)
                        .map((table, index) => (
                          <div className="flex flex-col w-full gap-[8px]">
                            {table.guest_list.map((guest, index) => (
                              <DraggableGuestCard
                                data={guest}
                                index={index}
                                key={guest.guest_id}
                              />
                            ))}
                          </div>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>

            {/* Table lists area */}
            <div className="flex flex-col w-full h-[calc(100vh-80px)] p-[12px] xl:p-[24px] no-scrollbar overflow-y-auto">
              {mode === "normal" ? (
                <NormalMode
                  setMode={setMode}
                  tableData={tableData}
                  handleRefresh={handleRefresh}
                />
              ) : (
                mode === "swap" && (
                  <SwapMode
                    setMode={setMode}
                    tableData={tableData}
                    handleRefresh={handleRefresh}
                  />
                )
              )}
            </div>
          </div>
        </DragDropContext>
      </LoadingInComponent>
    </div>
  );
};

export default SeatingChart;
