import React from "react";

const WidgetCard = ({ name, data, content }) => {
  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit p-[24px] gap-[12px] bg-white rounded-xl">
      <h2 className="text-[14px] truncate">{name}</h2>
      <h1 className="text-[29px]">{data}</h1>
      <div>{content}</div>
    </div>
  );
};

export default WidgetCard;
