import { XMarkIcon } from "@heroicons/react/24/solid";
import LoadingInComponent from "../LoadingInComponent";
import React from "react";
import Button from "../Button";
import {
  ArrowLeft2,
  Call,
  Coffee,
  ExportCircle,
  ImportCircle,
  Sms,
} from "iconsax-react";
import SectionCard from "../SectionCard";
import TextboxSet from "../TextboxSet";
import GroupMemberListing from "./GroupMemberListing";

const CheckInSliderStep2 = ({
  guestInfo,
  groupMembers,
  loading,
  setIsOpen,
  setStep,
  handleCheckIn,
  handleCheckOut,
}) => {
  return (
    <div className="absolute right-0 top-0 flex flex-col bg-[#F7F8F9] w-6/12 lg:w-4/12 h-screen no-scrollbar overflow-y-auto">
      <LoadingInComponent isLoading={loading}>
        <div className="flex flex-col w-full p-[24px] gap-[24px] bg-white">
          <div className="flex w-full justify-between">
            <Button variant="secondary" onClick={() => setStep(1)}>
              <ArrowLeft2 size="16" />
              Back
            </Button>
            <XMarkIcon
              className="size-[24px] hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-[24px]">
            {/* Table Name */}
            <div className="flex flex-col border border-[#EFF0F2] rounded-xl justify-center items-center py-[12px] px-[41px]">
              <p className="text-[11px] font-normal">Table</p>
              <h1 className="text-[42px]">
                {guestInfo.table_name ? guestInfo.table_name : "No table"}
              </h1>
            </div>

            {/* Guest details */}
            <div className="flex flex-col w-full justify-center gap-[8px]">
              <h2 className="w-full text-center text-[17px]">
                {guestInfo.salutation && `${guestInfo.salutation}. `}
                {guestInfo.name}
              </h2>
              <div className="flex w-full gap-[8px] justify-center text-[#636C7E]">
                <div className="flex gap-[4px] items-center text-[12px] font-normal">
                  <Sms size="12" variant="Bold" />
                  {guestInfo.email ? guestInfo.email : "None"}
                </div>
                <div className="flex gap-[4px] items-center text-[12px] font-normal">
                  <Call size="12" variant="Bold" />
                  {guestInfo.contact ? guestInfo.contact : "None"}
                </div>
                <div className="flex gap-[4px] items-center text-[12px] font-normal">
                  <Coffee size="12" variant="Bold" />
                  {guestInfo.dietary_name ? guestInfo.dietary_name : "None"}
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex w-full justify-center">
              {guestInfo.status ? (
                <Button
                  variant="error"
                  onClick={() => handleCheckOut({ id: guestInfo.id })}
                >
                  <ExportCircle size="16" variant="Outline" />
                  Check Out
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => handleCheckIn({ id: guestInfo.id })}
                >
                  <ImportCircle size="16" variant="Outline" />
                  Check In
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full p-[24px] gap-[24px]">
          <SectionCard
            title="Notes"
            collapsible
            content={
              <TextboxSet
                readOnly
                type="textarea"
                name="note"
                value={guestInfo.note}
              />
            }
          />
          <GroupMemberListing
            members={groupMembers}
            handleCheckIn={handleCheckIn}
            handleCheckOut={handleCheckOut}
          />
        </div>
      </LoadingInComponent>
    </div>
  );
};

export default CheckInSliderStep2;
