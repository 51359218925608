import React, { useState } from "react";
import { ArrowSwapHorizontal } from "iconsax-react";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid";
import Button from "../../Components/Button";
import FilterTextInput from "../../Components/FilterTextInput";
import TableContainer from "../../Components/SeatingChart/TableContainer";

const SwapMode = ({ tableData, setMode }) => {
  const [collapseAll, setCollapseAll] = useState(false);
  const [leftSearchTerm, setLeftSearchTerm] = useState("");
  const [rightSearchTerm, setRightSearchTerm] = useState("");

  const leftFilteredTableData = tableData
    .filter((table) => table.table_id !== -2)
    .filter((table) => {
      const guestMatch = table.guest_list.some((guest) =>
        guest.guest_name.toLowerCase().includes(leftSearchTerm.toLowerCase())
      );

      const tableMatch = table.table_name
        .toLowerCase()
        .includes(leftSearchTerm.toLowerCase());

      return tableMatch || guestMatch;
    });

  const rightFilteredTableData = tableData
    .filter((table) => table.table_id !== -2)
    .filter((table) => {
      const guestMatch = table.guest_list.some((guest) =>
        guest.guest_name.toLowerCase().includes(rightSearchTerm.toLowerCase())
      );

      const tableMatch = table.table_name
        .toLowerCase()
        .includes(rightSearchTerm.toLowerCase());

      return tableMatch || guestMatch;
    });

  const handleCollapseAll = () => {
    setCollapseAll((prev) => !prev);
  };

  return (
    <div className="flex flex-col h-fit gap-[12px] lg:gap-[24px]">
      <div className="flex w-full gap-[8px] items-center justify-end">
        <Button variant="secondary" onClick={handleCollapseAll}>
          {collapseAll ? (
            <ArrowsPointingOutIcon className="size-[16px]" />
          ) : (
            <ArrowsPointingInIcon className="size-[16px]" />
          )}
          {collapseAll ? "Expand" : "Collapse"}
        </Button>
        <Button
          variant="secondary"
          className="text-red-500"
          onClick={() => setMode("normal")}
        >
          <ArrowSwapHorizontal size="16" />
          Exit Swap Mode
        </Button>
      </div>
      <div className="flex w-full gap-[12px] xl:gap-[24px]">
        <div className="flex flex-col w-full">
          {/* Left */}
          <div className="flex flex-col w-full gap-[24px] items-center">
            <FilterTextInput
              placeholder="Search tables or guests"
              className="w-full"
              value={leftSearchTerm}
              onChange={(e) => setLeftSearchTerm(e.target.value)}
            />
            {leftFilteredTableData.map((table, index) => (
              <TableContainer
                data={table}
                key={table.table_id}
                index={index}
                collapseAll={collapseAll}
              />
            ))}
          </div>
        </div>
        <div className="border border-[#EFF0F2]"></div>
        <div className="flex flex-col w-full">
          {/* Right */}
          <div className="flex flex-col w-full gap-[24px] items-center">
            <FilterTextInput
              placeholder="Search tables or guests"
              className="w-full"
              value={rightSearchTerm}
              onChange={(e) => setRightSearchTerm(e.target.value)}
            />
            {rightFilteredTableData.map((table, index) => (
              <TableContainer
                data={table}
                key={table.table_id}
                index={index}
                collapseAll={collapseAll}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapMode;
