import { Eye, EyeSlash } from "iconsax-react";
import { useState } from "react";

const TextboxSet = ({
  type = "text",
  name,
  placeholder,
  className,
  value,
  label,
  disabled,
  readOnly,
  required,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  let label_classes = `flex focused:text-[#365BC9] text-[14px]`;
  let text_classes = `${className} border border-[#EFF0F2] rounded-lg hover:border-black focus:border-[#5C4BF5] focus:outline-[#5C4BF5] focus:shadow-[0px_0px_4px_0px_#6200EE80] disabled:border-[#D0D3D8] disabled:hover:border-[#D0D3D8] disabled:placeholder:text-[#D0D3D8] font-normal py-[8px] px-[16px]`;
  return (
    <>
      {type === "password" ? (
        <div className="flex flex-col w-full gap-[6px]">
          <h2
            className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </h2>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name={name}
              className={`${text_classes} w-full pr-[40px]`}
              placeholder={disabled ? "Disabled" : placeholder}
              value={value}
              onChange={onChange}
              disabled={disabled}
              readOnly={readOnly}
            />
            {showPassword ? (
              <Eye
                size="16"
                variant="Bold"
                className="absolute top-[4px] right-[5px] mt-2 mr-2"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            ) : (
              <EyeSlash
                size="16"
                variant="Bold"
                className="absolute top-[4px] right-[5px] mt-2 mr-2"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            )}
          </div>
        </div>
      ) : type === "textarea" ? (
        <div className="flex flex-col w-full gap-[6px]">
          <h2
            className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </h2>
          <textarea
            name={name}
            className={text_classes}
            placeholder={disabled ? "Disabled" : placeholder}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            rows={5}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col w-full gap-[6px]">
          <h2
            className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}
          >
            {label} {required && <span className="text-red-500">*</span>}
          </h2>
          <input
            type={type === "number" ? null : type}
            name={name}
            className={text_classes}
            placeholder={disabled ? "Disabled" : placeholder}
            value={value}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => {
              if (type === "number") {
                const regex = /^[0-9]*$/;
                if (regex.test(e.target.value) || e.target.value === "") {
                  onChange(e);
                }
              } else {
                onChange(e);
              }
            }}
          />
        </div>
      )}
    </>
  );
};

export default TextboxSet;
