import React from "react";
import TextboxSet from "./TextboxSet";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EmailBlastForm = ({ formData, handleChange }) => {
  return (
    <form className="flex flex-col w-full h-full bg-white border border-[#EFF0F2] rounded-xl p-[24px] gap-[24px]">
      <div className="flex w-full">
        <TextboxSet
          label="Email Template Name"
          name="name"
          required
          value={formData.name}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="flex w-full gap-[24px]">
        <TextboxSet
          label="Send Email From Email"
          name="from_email"
          type="email"
          value={formData.from_email}
          onChange={(e) => handleChange(e)}
        />
        <TextboxSet
          label="Send Email From Name"
          name="from_name"
          value={formData.from_name}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="flex w-full">
        <TextboxSet
          label="Email Subject"
          name="subject"
          value={formData.subject}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="flex w-full">
        <TextboxSet
          label="Email Preheader Text"
          name="preheader"
          value={formData.preheader}
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="flex flex-col w-full gap-[8px]">
        <h2 className="text-[14px]">Content</h2>
        <ReactQuill
          style={{
            marginBottom: "48px",
            width: "100%",
            maxHeight: "484px",
          }}
          value={formData.content}
          onChange={(e) =>
            handleChange({ target: { name: "content", value: e } })
          }
        />
      </div>
    </form>
  );
};

export default EmailBlastForm;
