import React, { useState, useEffect } from "react";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { Droppable } from "react-beautiful-dnd";
import DraggableGuestCard from "./DraggableGuestCard";

const TableContainer = ({ data, index, collapseAll }) => {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    setCollapsed(collapseAll);
  }, [collapseAll]);

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div className="flex flex-col w-full h-fit border border-[#EFF0F2] bg-white rounded-xl">
      {/* Table Name */}
      <div
        className={
          collapsed
            ? "flex w-full p-[16px] items-center justify-between"
            : "flex w-full p-[16px] items-center justify-between border-b border-[#EFF0F2]"
        }
      >
        <h2>{data.table_name}</h2>
        <div className="flex gap-[8px] items-center text-[#636C7E] font-normal text-[12px]">
          <p>
            {data.guest_list.length}/{data.pax} Seats
          </p>
          {collapsed ? (
            <ArrowDown2
              size="16"
              variant="Outline"
              className="hover:cursor-pointer"
              onClick={toggleCollapse}
            />
          ) : (
            <ArrowUp2
              size="16"
              variant="Outline"
              className="hover:cursor-pointer"
              onClick={toggleCollapse}
            />
          )}
        </div>
      </div>

      {/* DND Content */}
      {!collapsed && (
        <Droppable droppableId={`${data.table_id}`} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="flex flex-col w-full min-h-[210px]"
            >
              {data.guest_list.map((guest, index) => (
                <DraggableGuestCard
                  data={guest}
                  index={index}
                  key={guest.guest_id}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
};

export default TableContainer;
