import React, { useState } from "react";
import FilterTextInput from "../FilterTextInput";
import ReactPaginate from "react-paginate";
import {
  Add,
  ArrowLeft2,
  ArrowRight2,
  Clock,
  Edit2,
  ExportSquare,
  TickCircle,
} from "iconsax-react";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { GUEST_ADD, GUEST_EDIT } from "../../Router/path";
import axios from "axios";
import { EXPORT_GUEST_API } from "../../Constant/api";
import { useToast } from "../Toast";
import fileDownload from "js-file-download";
import PerPageSelector from "../PerPageSelector";

const GuestListTable = ({
  withHeaderAction,
  title,
  editable,
  searchable,
  groupable,
  data,
  meta,
  handlePageClick,
  searchTerm,
  handleSearch,
  handlePerPageChange,
  perPage,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const currentPage = meta ? (meta.page > 0 ? meta.page - 1 : 0) : null;

  const exportGuestList = async () => {
    try {
      axios
        .post(
          EXPORT_GUEST_API,
          { eventId: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
              TimezoneOffset: new Date().getTimezoneOffset(),
            },
          }
        )
        .then((res) => {
          fileDownload(res.data, "guest_list.csv");
        })
        .catch((err) => {
          toast("Failed to fetch guest list template.", "error");
          console.log(err);
        });
    } catch (error) {
      toast("Failed to fetch guest list template.", "error");
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {/* Title */}
      {title && (
        <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[16px] justify-between items-center">
          <h2>{title}</h2>
        </div>
      )}

      {/* Header Action */}
      {withHeaderAction && (
        <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[16px] justify-between items-center">
          <h2>Guest List</h2>
          <div className="flex gap-[8px]">
            <Button variant="secondary" onClick={exportGuestList}>
              <ExportSquare size="16" color="#000000" variant="Outline" />{" "}
              Export Guest List
            </Button>
            <Button variant="primary" onClick={() => navigate(GUEST_ADD)}>
              <Add size={16} color="#FFFFFF" variant="Outline" /> Add Guest
            </Button>
          </div>
        </div>
      )}

      {/* Filters */}
      {searchable && (
        <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
          <FilterTextInput value={searchTerm} onChange={handleSearch} />
        </div>
      )}

      {/* Table data */}
      <div className="flex w-full h-full gap-[24px] overflow-x-auto">
        {!data || data.length === 0 ? (
          <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
            No guests entry
          </div>
        ) : (
          <table className="w-full h-full">
            {/* Table Header */}
            <tr className="w-full bg-[#F7F8F9] border-b border-[#EFF0F2]">
              {/* <th className="text-start px-[24px] py-[16px]">
                <input type="checkbox" />
              </th> */}
              <th className="text-start px-[24px] py-[16px]">Title</th>
              <th className="text-start px-[24px] py-[16px]">Guest Name</th>
              <th className="text-start px-[24px] py-[16px]">
                Check-In Status
              </th>
              <th className="text-start px-[24px] py-[16px]">Email</th>
              <th className="text-start px-[24px] py-[16px]">Contact</th>
              <th className="text-start px-[24px] py-[16px]">Group</th>
              <th className="text-start px-[24px] py-[16px]">Table</th>
              <th className="text-start px-[24px] py-[16px]">
                Special Dietary
              </th>
              {editable && <th className="text-start px-[24px] py-[16px]"></th>}
            </tr>

            {/* Table Body */}
            {data &&
              data.map((item, index) => (
                <tr
                  key={item.id}
                  className="w-full font-normal border-b last:border-none border-[#EFF0F2]"
                >
                  {/* <td className="text-start px-[24px] py-[16px]">
                    <input type="checkbox" />
                  </td> */}
                  <td className="px-[24px] py-[16px]">
                    {item.salutation ?? "-"}
                  </td>
                  <td className="px-[24px] py-[16px]">{item.name ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">
                    {item.status === true ? (
                      <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#F4FCF7] border border-[#E9F9EF] text-[#22C55E]">
                        <TickCircle size="16" variant="Bold" />
                        Checked
                      </div>
                    ) : (
                      <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#FFFBF3] border border-[#FEF6E6] text-[#F0A009]">
                        <Clock size="16" variant="Bold" />
                        Pending
                      </div>
                    )}
                  </td>
                  <td className="px-[24px] py-[16px]">{item.email ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">{item.contact ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">
                    {item.group_name ?? "-"}
                  </td>
                  <td className="px-[24px] py-[16px]">
                    {item.table_name ?? "-"}
                  </td>
                  <td className="px-[24px] py-[16px]">
                    {item.dietary_name ?? "-"}
                  </td>
                  {editable && (
                    <td className="flex px-[24px] py-[16px] justify-end">
                      <span
                        className="flex text-[#5C4BF5] text-[14px] items-center hover:underline cursor-pointer"
                        onClick={() => navigate(GUEST_EDIT, { state: item.id })}
                      >
                        <Edit2 size="15" variant="Bold" />
                        Edit
                      </span>
                    </td>
                  )}
                </tr>
              ))}
          </table>
        )}
      </div>

      {/* Pagination */}
      {meta && (
        <div className="grid grid-cols-3 w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center font-normal">
          {/* Page number and total */}
          <div className="grid w-full">
            Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
          </div>

          {/* Number per page selector */}
          <div className="grid w-full justify-center">
            <PerPageSelector
              handlePerPageChange={handlePerPageChange}
              perPage={perPage}
            />
          </div>

          {/* Page selector */}
          <div className="grid w-full justify-end">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={meta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestListTable;
