import React, { useState, useEffect } from "react";
import Select from "react-select";

const DropdownSet = ({
  value,
  options,
  label,
  name,
  disabled,
  required,
  onChange,
  isMulti,
  variant = "default",
}) => {
  const transformedOptions = options.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const label_variants = {
    default: "focused:text-[#365BC9]",
  };

  let label_classes = `flex ${label_variants[variant]}`;
  return (
    <>
      <div className="flex flex-col w-full gap-[6px]">
        <h2 className={`${label_classes} ${disabled ? "text-[#D0D3D8]" : ""}`}>
          {label} {required && <span className="text-red-500">*</span>}
        </h2>
        <Select
          options={transformedOptions}
          isDisabled={disabled}
          name={name}
          value={transformedOptions.find(
            (option) => option.value === value ?? null
          )}
          isMulti={isMulti}
          isSearchable
          onChange={(e) => {
            if (isMulti) {
              onChange(
                name,
                e.map((item) => item.value)
              );
            } else {
              e !== null ? onChange(name, e.value) : onChange(name, null);
            }
          }}
          placeholder={`Select ${label}`}
          isClearable
        />
      </div>
    </>
  );
};

export default DropdownSet;
