import React from "react";
import FilterTextInput from "../FilterTextInput";
import ReactPaginate from "react-paginate";
import {
  ArrowLeft2,
  ArrowRight2,
  Clock,
  Edit2,
  TickCircle,
} from "iconsax-react";
import PerPageSelector from "../PerPageSelector";

const ReportTable = ({
  data,
  meta,
  handlePageClick,
  handleSearch,
  searchTerm,
  perPage,
  handlePerPageChange,
}) => {
  const currentPage = meta ? (meta.page > 0 ? meta.page - 1 : 0) : null;

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput value={searchTerm} onChange={handleSearch} />
      </div>

      {/* Table data */}
      <div className="flex w-full h-full gap-[24px] no-scrollbar overflow-x-auto">
        {!data || data.length === 0 ? (
          <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
            No guests entry
          </div>
        ) : (
          <table className="w-full h-full">
            {/* Table Header */}
            <tr className="w-full bg-[#F7F8F9] border-b border-[#EFF0F2]">
              <th className="text-start px-[24px] py-[16px]">Guest</th>
              <th className="text-start px-[24px] py-[16px]">Table</th>
              <th className="text-start px-[24px] py-[16px]">
                Check-In Status
              </th>
            </tr>

            {/* Table Body */}
            {data &&
              data.map((item) => (
                <tr
                  key={item.id}
                  className="w-full font-normal border-b last:border-none border-[#EFF0F2]"
                >
                  <td className="px-[24px] py-[16px]">{item.guest ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">{item.table ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">
                    {item.status === true ? (
                      <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#F4FCF7] border border-[#E9F9EF] text-[#22C55E]">
                        <TickCircle size="16" variant="Bold" />
                        Checked
                      </div>
                    ) : (
                      <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#FFFBF3] border border-[#FEF6E6] text-[#F0A009]">
                        <Clock size="16" variant="Bold" />
                        Pending
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </table>
        )}
      </div>

      {/* Pagination */}
      {meta && (
        <div className="grid grid-cols-3 w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center justify-between font-normal">
          {/* Page number and total */}
          <div className="grid w-full">
            Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
          </div>

          {/* Number per page selector */}
          <div className="grid w-full justify-center">
            <PerPageSelector
              perPage={perPage}
              handlePerPageChange={handlePerPageChange}
            />
          </div>

          {/* Page selector */}
          <div className="grid w-full justify-end">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={meta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTable;
