import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import Button from "../../Components/Button";
import TextboxSet from "../../Components/TextboxSet";
import TimestampCard from "../../Components/TimestampCard";
import SectionCard from "../../Components/SectionCard";
import { DIETARY_MANAGEMENT } from "../../Router/path";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  DELETE_DIETARY_API,
  GET_DIETARY_DETAILS_API,
  GET_DIETARY_GUESTS_API,
  REMOVE_GUEST_DIETARY_API,
  UPDATE_DIETARY_API,
} from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import DietaryEditTable from "../../Components/Tables/DietaryEditTable";
import Modal from "../../Components/Modal";
import { Warning2 } from "iconsax-react";

const DietaryEdit = () => {
  const location = useLocation();
  const dietaryId = location.state;

  const [loading, setLoading] = useState(false);
  const [dietaryDetails, setDietaryDetails] = useState({});
  const [dietaryGuests, setDietaryGuests] = useState([]);
  const [dietaryGuestsMeta, setDietaryGuestsMeta] = useState({});
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { toast } = useToast();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleOnChange = (e) => {
    setDietaryDetails({ ...dietaryDetails, [e.target.name]: e.target.value });
  };

  const fetchDietaryDetails = () => {
    setLoading(true);
    try {
      axios
        .get(GET_DIETARY_DETAILS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { id: dietaryId },
        })
        .then((response) => {
          setDietaryDetails(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch dietary details", "error");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      toast("Failed to fetch dietary details", "error");
      console.log(error);
      setLoading(false);
    }
  };

  const fetchDietaryGuests = () => {
    setLoading(true);
    try {
      axios
        .get(GET_DIETARY_GUESTS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { id: dietaryId },
        })
        .then((response) => {
          setDietaryGuests(response.data.data);
          setDietaryGuestsMeta(response.data.meta.pagination);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch dietary guests", "error");
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      toast("Failed to fetch dietary guests", "error");
      console.log(error);
      setLoading(false);
    }
  };

  const handleDietaryUpdate = () => {
    setLoading(true);
    try {
      axios
        .put(
          UPDATE_DIETARY_API,
          {
            name: dietaryDetails.dietary_name,
            id: dietaryId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          navigate(DIETARY_MANAGEMENT);
          toast("Dietary updated successfully", "success");
        });
    } catch (error) {
      toast("Failed to update dietary", "error");
      console.error(error);
    }
  };

  const handleRemoveGuest = (guestId) => {
    try {
      axios
        .put(
          REMOVE_GUEST_DIETARY_API,
          {
            guestId: guestId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          fetchDietaryGuests();
          toast("Guest removed successfully", "success");
        })
        .catch((error) => {
          toast("Failed to remove guest", "error");
          console.error(error);
        });
    } catch (error) {
      toast("Failed to remove guest", "error");
      console.error(error);
    }
  };

  const handleDeleteDietary = () => {
    setLoading(true);
    try {
      axios
        .delete(DELETE_DIETARY_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { dietaryId: dietaryId },
        })
        .then((response) => {
          setLoading(false);
          navigate(DIETARY_MANAGEMENT);
          toast("Dietary deleted successfully", "success");
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to delete dietary", "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to delete dietary", "error");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDietaryDetails();
    fetchDietaryGuests();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {showModal && (
        <Modal
          title="Delete Dietary"
          confirmation={handleDeleteDietary}
          toggleModal={toggleModal}
          submitLabel="Remove"
          content={
            <div className="flex flex-col w-full items-center justify-center gap-[16px] py-[32px]">
              <Warning2 size="64" color="#ff0000" variant="Bold" />
              <p>Are you sure you want to remove this guest?</p>
            </div>
          }
        />
      )}
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title */}
            <div className="flex justify-between items-center">
              <h1 className="text-[29px]">
                {dietaryDetails.dietary_name} Edit
              </h1>
              <Button variant="error" onClick={toggleModal}>
                Delete
              </Button>
            </div>

            {/* Widgets */}
            <div className="flex w-full gap-[24px]">
              <SectionCard
                content={
                  <TextboxSet
                    label="Special Dietary"
                    name="dietary_name"
                    required
                    value={dietaryDetails.dietary_name}
                    onChange={(e) => handleOnChange(e)}
                  />
                }
              />
              <SectionCard
                className={"max-w-[300px]"}
                content={
                  <TimestampCard
                    created_at={dietaryDetails.created_at}
                    updated_at={dietaryDetails.updated_at}
                  />
                }
              />
            </div>

            {/* Button section */}
            <div className="flex w-full gap-[8px]">
              <Button onClick={handleDietaryUpdate}>Save Changes</Button>
              <Button
                variant="secondary"
                onClick={() => navigate(DIETARY_MANAGEMENT)}
              >
                Cancel
              </Button>
            </div>

            {/* Data */}
            <div className="w-full">
              <DietaryEditTable
                data={dietaryGuests}
                handleRemoveDietary={handleRemoveGuest}
                meta={dietaryGuestsMeta}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default DietaryEdit;
