import React, { useState, useEffect } from "react";
import WidgetCard from "../Components/WidgetCard";
import GuestListTable from "../Components/Tables/GuestListTable";
import axios from "axios";
import { GUEST_SUMMARY_API, GUEST_LIST_API } from "../Constant/api";
import { useToast } from "../Components/Toast";
import LoadingInComponent from "../Components/LoadingInComponent";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState();
  const [guestListMeta, setGuestListMeta] = useState({});
  const [guestSummary, setGuestSummary] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();

  const handlePageClick = (e) => {
    fetchGuestList(e.selected + 1);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchGuestList(1, e.target.value);
  };

  const formatDaysRemaining = (daysRemaining) => {
    if (daysRemaining === 0) {
      return "Today";
    } else if (daysRemaining < 0) {
      return "Ended";
    } else {
      return daysRemaining;
    }
  };

  const fetchGuestList = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GUEST_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            page: page,
            perPage: perPageValue,
            search: searchTerm,
          },
        })
        .then((res) => {
          setLoading(false);
          setGuestList(res.data.data);
          setGuestListMeta(res.data.meta.pagination);
        })
        .catch((err) => {
          setLoading(false);
          toast("Failed to fetch guest list.", "error");
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch guest list.", "error");
      console.log(error);
    }
  };

  const fetchGuestSummary = async () => {
    setLoading(true);
    try {
      axios
        .get(GUEST_SUMMARY_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((res) => {
          setLoading(false);
          setGuestSummary(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          toast("Failed to fetch guest summary.", "error");
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch guest summary.", "error");
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchGuestList();
    }
  };

  useEffect(() => {
    fetchGuestList();
    fetchGuestSummary();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] overflow-y-auto gap-[24px]">
            {/* Title */}
            <h1 className="text-[29px]">Dashboard</h1>

            {/* Widget */}
            {guestSummary && (
              <div className="flex w-full gap-[24px]">
                <WidgetCard
                  name="Total Guests"
                  data={guestSummary.total_guests}
                />
                <WidgetCard
                  name="Total Checked in Guests"
                  data={guestSummary.total_checked_in}
                />
                <WidgetCard
                  name="Coming Days"
                  data={formatDaysRemaining(guestSummary.days_remaining)}
                />
              </div>
            )}
            {/* Data */}
            <div>
              <GuestListTable
                withHeaderAction
                searchable
                data={guestList}
                meta={guestListMeta}
                handlePageClick={handlePageClick}
                handlePerPageChange={handlePerPageChange}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                perPage={perPage}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default Dashboard;
