import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DELETE_TABLE_API,
  DIETARY_OPTIONS_API,
  GET_TABLE_DETAILS_API,
  NO_TABLE_GUEST_OPTIONS_API,
  UPDATE_TABLE_API,
} from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import TextboxSet from "../../Components/TextboxSet";
import Counter from "../../Components/Counter";
import Button from "../../Components/Button";
import LoadingInComponent from "../../Components/LoadingInComponent";
import WidgetCard from "../../Components/WidgetCard";
import axios from "axios";
import SectionCard from "../../Components/SectionCard";
import TimestampCard from "../../Components/TimestampCard";
import { SEATS_MANAGEMENT } from "../../Router/path";
import SeatManagementEditTable from "./SeatManagementEditTable";
import Modal from "../../Components/Modal";
import { Warning2 } from "iconsax-react";

const SeatManagementEdit = () => {
  const [loading, setLoading] = useState(false);
  const [tableInfo, setTableInfo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    pax: 1,
  });
  const [guestList, setGuestList] = useState();
  const [guestListOption, setGuestListOption] = useState([]);

  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();

  const tableId = location.state.tableId;

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchGuestList = async () => {
    setLoading(true);
    axios
      .get(NO_TABLE_GUEST_OPTIONS_API, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
        },
        params: {
          eventId: localStorage.getItem("event_id"),
        },
      })
      .then((response) => {
        setLoading(false);
        setGuestListOption(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast("Failed to fetch guest list", "error");
      });
  };

  const fetchTableDetail = async () => {
    setLoading(true);
    axios
      .get(GET_TABLE_DETAILS_API, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
        },
        params: {
          tableId: tableId,
        },
      })
      .then((response) => {
        setLoading(false);
        setTableInfo(response.data.data);
        setFormData({
          name: response.data.data.name,
          description: response.data.data.description,
          pax: response.data.data.pax,
        });
        setGuestList(response.data.data.guests);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast("Failed to fetch table details", "error");
      });
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      axios
        .put(UPDATE_TABLE_API, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            tableId: tableId,
          },
        })
        .then((response) => {
          setLoading(false);
          navigate(SEATS_MANAGEMENT);
          toast("Table updated successfully", "success");
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast(error.response.data.message, "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to update table", "error");
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    axios
      .delete(DELETE_TABLE_API, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
        },
        params: {
          tableId: tableId,
        },
      })
      .then((response) => {
        setLoading(false);
        navigate(SEATS_MANAGEMENT);
        toast("Table deleted successfully", "success");
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast("Failed to delete table", "error");
      });
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    fetchGuestList();
    fetchTableDetail();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {showModal && (
        <Modal
          toggleModal={toggleModal}
          confirmation={handleDelete}
          content={
            <div className="flex flex-col w-full items-center justify-center gap-[16px] py-[32px]">
              <Warning2 size="64" color="#ff0000" variant="Bold" />
              <p>Are you sure you want to delete this table?</p>
            </div>
          }
        />
      )}
      {/* Create modal */}
      <LoadingInComponent isLoading={loading}>
        {!loading && tableInfo && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Table {tableInfo.name} Edit</h1>
              <Button variant="error" onClick={toggleModal}>
                Delete
              </Button>
            </div>

            {/* Table Detail Form */}
            <div className="flex w-full gap-[24px]">
              <SectionCard
                content={
                  <div className="flex flex-col w-full gap-[24px]">
                    <div className="flex gap-[24px]">
                      <TextboxSet
                        label="Table Name"
                        name="name"
                        type="text"
                        value={formData.name}
                        required
                        onChange={(e) => handleChange(e)}
                      />
                      <Counter
                        title="Number of seats"
                        required
                        formData={formData}
                        setFormData={setFormData}
                      />
                    </div>
                    <TextboxSet
                      label="Table Description"
                      name="description"
                      type="textarea"
                      value={formData.description}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                }
              />
              <SectionCard
                className={"max-w-[300px]"}
                content={
                  <TimestampCard
                    created_at={tableInfo.created_at}
                    updated_at={tableInfo.updated_at}
                  />
                }
              />
            </div>

            {/* Button */}
            <div className="flex w-full gap-[8px]">
              <Button onClick={handleUpdate}>Save Changes</Button>
              <Button
                variant="secondary"
                onClick={() => navigate(SEATS_MANAGEMENT)}
              >
                Cancel
              </Button>
            </div>

            {/* Widgets */}
            <div className="flex w-full gap-[24px]">
              <WidgetCard
                name="Total Check-In Guests"
                data={tableInfo.widget.total_guests_checked_in}
              />
              <WidgetCard
                name="Assigned/Seats"
                data={tableInfo.widget.assigned_guests}
              />
              <WidgetCard
                name="Special Dietary"
                data={tableInfo.widget.special_dietary}
              />
            </div>

            {/* Table */}
            <div className="w-full">
              <SeatManagementEditTable
                tableId={tableId}
                data={guestList}
                // dietaryList={dietaryListOption}
                guestList={guestListOption}
                fetchTableDetail={fetchTableDetail}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default SeatManagementEdit;
