import React from "react";
import FilterTextInput from "../FilterTextInput";
import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";

const GuestUploadPreviewTable = ({
  title,
  searchable,
  data,
  meta,
  handlePageClick,
  searchTerm,
  handleSearch,
}) => {
  const currentPage = meta ? (meta.page > 0 ? meta.page - 1 : 0) : null;

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {/* Title */}
      {title && (
        <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[16px] justify-between items-center">
          <h2>{title}</h2>
        </div>
      )}

      {/* Filters */}
      {searchable && (
        <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
          <FilterTextInput value={searchTerm} onChange={handleSearch} />
        </div>
      )}

      {/* Table data */}
      <div className="flex w-full h-full gap-[24px] no-scrollbar overflow-x-auto">
        {!data || data.length === 0 ? (
          <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
            No guests entry
          </div>
        ) : (
          <table className="w-full h-full">
            {/* Table Header */}
            <tr className="w-full bg-[#F7F8F9]">
              <th className="text-start px-[24px] py-[16px]">
                Title/Salutation
              </th>
              <th className="text-start px-[24px] py-[16px]">Guest Name</th>
              <th className="text-start px-[24px] py-[16px]">Email</th>
              <th className="text-start px-[24px] py-[16px]">Contact</th>
              <th className="text-start px-[24px] py-[16px]">Group</th>
            </tr>

            {/* Table Body */}
            {data &&
              data.map((item) => (
                <tr
                  key={item.id}
                  className="w-full font-normal border-y border-[#EFF0F2] last:border-none"
                >
                  <td className="px-[24px] py-[16px]">
                    {item.salutation ?? "-"}
                  </td>
                  <td className="px-[24px] py-[16px]">{item.name ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">{item.email ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">{item.contact ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">
                    {item.group_name ?? "-"}
                  </td>
                </tr>
              ))}
          </table>
        )}
      </div>

      {/* Pagination */}
      {meta && (
        <div className="flex w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center justify-between font-normal">
          {/* Page number and total */}
          <div className="flex w-full">
            Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
          </div>

          {/* Number per page selector */}
          <div className="flex w-full"></div>

          {/* Page selector */}
          <div className="flex w-full justify-end">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={meta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestUploadPreviewTable;
