import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

const MainLayout = ({ children, showSidebar = true }) => {
  const [isSidebarVisible, setSidebarVisible] = React.useState(showSidebar);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  return (
    <div>
      <Header toggleSidebar={toggleSidebar} />
      <div className="flex w-full h-[calc(100vh-80px)]">
        {isSidebarVisible && <Sidebar />}
        {/* <div className="flex flex-col w-full h-full">{children}</div> */}
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
