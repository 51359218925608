import React, { useState, useEffect } from "react";
import LoadingInComponent from "../Components/LoadingInComponent";
import Button from "../Components/Button";
import WidgetCard from "../Components/WidgetCard";
import axios from "axios";
import {
  EXPORT_GUEST_REPORT_API,
  GET_GUEST_REPORT_API,
  GET_REPORT_WIDGET_API,
} from "../Constant/api";
import { useToast } from "../Components/Toast";
import ReportTable from "../Components/Tables/ReportTable";
import fileDownload from "js-file-download";

const ReportOverview = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [widgetData, setWidgetData] = useState();
  const [tableData, setTableData] = useState();
  const [tableMeta, setTableMeta] = useState();
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();

  const handlePageClick = (e) => {
    fetchTableData(e.selected + 1);
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchTableData(1, e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchTableData();
    }
  };

  const fetchWidgetData = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_REPORT_WIDGET_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setLoading(false);
          setWidgetData(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch widget data", "error");
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch widget data", "error");
    }
  };

  const fetchTableData = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_GUEST_REPORT_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            page: page,
            search: searchTerm,
            perPage: perPageValue,
          },
        })
        .then((response) => {
          setLoading(false);
          setTableData(response.data.data);
          setTableMeta(response.data.meta.pagination);
        })
        .catch((error) => {
          setLoading(false);
          toast("Failed to fetch table data", "error");
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch table data", "error");
    }
  };

  const exportGuestReport = async () => {
    try {
      axios
        .post(
          EXPORT_GUEST_REPORT_API,
          { eventId: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((res) => {
          fileDownload(res.data, "guest_report.csv");
        })
        .catch((err) => {
          toast("Failed to fetch guest list template.", "error");
          console.log(err);
        });
    } catch (error) {
      toast("Failed to fetch guest list template.", "error");
      console.log(error);
    }
  };

  useEffect(() => {
    fetchWidgetData();
    fetchTableData();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Report Overview</h1>
              <Button variant="primary" onClick={exportGuestReport}>
                Export Report
              </Button>
            </div>

            {/* Widgets */}
            {widgetData && (
              <div className="flex w-full gap-[24px]">
                <WidgetCard
                  name="Attended Guests"
                  data={widgetData.attended_guests}
                />
                <WidgetCard
                  name="Not Attending Guests"
                  data={widgetData.not_attended_guests}
                />
              </div>
            )}

            {/* Table */}
            {tableData && (
              <ReportTable
                data={tableData}
                meta={tableMeta}
                handlePageClick={handlePageClick}
                handleSearch={handleSearch}
                searchTerm={searchTerm}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            )}
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default ReportOverview;
