import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import Button from "../../Components/Button";
import SectionCard from "../../Components/SectionCard";
import TextboxSet from "../../Components/TextboxSet";
import DropdownSet from "../../Components/DropdownSet";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import {
  DIETARY_OPTIONS_API,
  GUEST_DELETE_API,
  GUEST_GROUP_API,
  GUEST_INFO_API,
  GUEST_UPDATE_API,
  TABLE_OPTIONS_API,
} from "../../Constant/api";
import { useLocation, useNavigate } from "react-router-dom";
import { GUEST_LIST } from "../../Router/path";
import { Clock, InfoCircle, TickCircle, Warning2 } from "iconsax-react";
import Modal from "../../Components/Modal";
import GuestListTable from "../../Components/Tables/GuestListTable";
import TimestampCard from "../../Components/TimestampCard";

const GuestEdit = () => {
  const [guestLoading, setGuestLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [dietaryList, setDietaryList] = useState([]);
  const [guestInfo, setGuestInfo] = useState(null);
  const [guestGroupMembers, setGuestGroupMembers] = useState();
  const [guestGroupMeta, setGuestGroupMeta] = useState();

  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const guestId = location.state;

  const handlePageClick = (e) => {
    fetchGuestGroupMembers(e.selected + 1);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleOnChange = (e) => {
    setGuestInfo({ ...guestInfo, [e.target.name]: e.target.value });
  };

  const handleDropDownChange = (name, value) => {
    setGuestInfo({ ...guestInfo, [name]: value });
  };

  const fetchGuestInfo = () => {
    setGuestLoading(true);
    try {
      axios
        .get(GUEST_INFO_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { guestId: guestId },
        })
        .then((res) => {
          setGuestLoading(false);
          setGuestInfo(res.data.data);
        })
        .catch((err) => {
          setGuestLoading(false);
          console.log(err);
          toast("Failed to fetch guest info.", "error");
        });
    } catch (error) {
      setGuestLoading(false);
      console.log(error);
      toast("Failed to fetch guest info.", "error");
    }
  };

  const fetchGuestGroupMembers = () => {
    setGroupLoading(true);
    try {
      axios
        .get(GUEST_GROUP_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { guestId: guestId },
        })
        .then((res) => {
          setGroupLoading(false);
          setGuestGroupMembers(res.data.data);
          setGuestGroupMeta(res.data.meta.pagination);
        })
        .catch((err) => {
          setGroupLoading(false);
          console.log(err);
          toast("Failed to fetch guest group members.", "error");
        });
    } catch (error) {
      setGroupLoading(false);
      console.log(error);
      toast("Failed to fetch guest group members.", "error");
    }
  };

  const fetchDietaryList = () => {
    try {
      setGuestLoading(true);
      axios
        .get(DIETARY_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((res) => {
          setGuestLoading(false);
          setDietaryList(res.data.data);
        })
        .catch((err) => {
          setGuestLoading(false);
          console.log(err);
          toast("Failed to fetch dietary list.", "error");
        });
    } catch (error) {
      setGuestLoading(false);
      console.log(error);
      toast("Failed to fetch dietary list.", "error");
    }
  };

  const fetchTableList = () => {
    setGuestLoading(true);
    try {
      axios
        .get(TABLE_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((res) => {
          setGuestLoading(false);
          setTableList(res.data.data);
        })
        .catch((err) => {
          setGuestLoading(false);
          console.log(err);
          toast("Failed to fetch table list.", "error");
        });
    } catch (error) {
      setGuestLoading(false);
      console.log(error);
      toast("Failed to fetch table list.", "error");
    }
  };

  const handleDelete = () => {
    setGuestLoading(true);
    try {
      axios
        .delete(GUEST_DELETE_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { guest_id: guestId },
        })
        .then((res) => {
          setGuestLoading(false);
          navigate(GUEST_LIST);
          toast("Guest deleted successfully.", "success");
        })
        .catch((error) => {
          setGuestLoading(false);
          console.log(error);
          toast("Failed to delete guest.", "error");
        });
    } catch (error) {
      setGuestLoading(false);
      console.log(error);
      toast("Failed to delete guest.", "error");
    }
  };

  const handleUpdate = () => {
    setGuestLoading(true);
    try {
      axios
        .put(GUEST_UPDATE_API, guestInfo, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
        })
        .then((res) => {
          setGuestLoading(false);
          navigate(GUEST_LIST);
          toast("Guest information updated successfully.", "success");
        })
        .catch((err) => {
          setGuestLoading(false);
          console.log(err);
          toast("Failed to update guest information.", "error");
        });
    } catch (error) {
      setGuestLoading(false);
      console.log(error);
      toast("Failed to update guest information.", "error");
    }
  };

  useEffect(() => {
    setGuestInfo(null);
    setGuestGroupMembers(null);
    setGuestGroupMeta(null);
    fetchTableList();
    fetchDietaryList();
    fetchGuestInfo();
    fetchGuestGroupMembers();
  }, [guestId]);

  return (
    <div className="flex flex-col w-full h-full">
      {showModal && (
        <Modal
          toggleModal={toggleModal}
          confirmation={handleDelete}
          content={
            <div className="flex flex-col w-full items-center justify-center gap-[16px] py-[32px]">
              <Warning2 size="64" color="#ff0000" variant="Bold" />
              <p>Are you sure you want to delete this guest?</p>
            </div>
          }
        />
      )}
      <LoadingInComponent isLoading={guestLoading}>
        {!guestLoading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Edit Guest</h1>
              <div className="flex w-fit items-center gap-[8px]">
                <Button variant="error" onClick={toggleModal}>
                  Delete
                </Button>
              </div>
            </div>

            {/* Guest Information */}
            {guestInfo && (
              <div className="flex w-full gap-[24px]">
                <SectionCard
                  title="Guest Information"
                  content={
                    <div className="grid grid-cols-2 w-full gap-[24px]">
                      <TextboxSet
                        type="text"
                        name="salutation"
                        value={guestInfo.salutation}
                        label="Title/Salutation"
                        className="w-full"
                        onChange={(e) => handleOnChange(e)}
                      />
                      <TextboxSet
                        type="text"
                        name="name"
                        value={guestInfo.name}
                        label="Full Name"
                        required
                        className="w-full"
                        onChange={(e) => handleOnChange(e)}
                      />
                      <TextboxSet
                        type="email"
                        name="email"
                        value={guestInfo.email}
                        label="Email"
                        className="w-full"
                        onChange={(e) => handleOnChange(e)}
                      />
                      <TextboxSet
                        type="number"
                        name="contact"
                        value={guestInfo.contact}
                        label="Contact Number"
                        className="w-full"
                        onChange={(e) => handleOnChange(e)}
                      />
                      <DropdownSet
                        value={guestInfo.table_id}
                        options={tableList}
                        label="Table"
                        name="table_id"
                        onChange={(name, value) =>
                          handleDropDownChange(name, value)
                        }
                      />
                      <DropdownSet
                        value={guestInfo.dietary_id}
                        options={dietaryList}
                        label="Special Dietary"
                        name="dietary_id"
                        onChange={(name, value) =>
                          handleDropDownChange(name, value)
                        }
                      />
                      <div className="flex flex-col w-full gap-[8px]">
                        <h2>
                          Check-In Status
                          <span className="text-red-500">*</span>
                        </h2>
                        <div className="flex gap-[16px]">
                          <Button
                            className="flex items-center gap-[8px]"
                            variant={
                              guestInfo.status === false
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() => {
                              setGuestInfo({ ...guestInfo, status: false });
                            }}
                          >
                            <Clock size="16" variant="Bold" />
                            Pending
                          </Button>
                          <Button
                            className="flex items-center gap-[8px]"
                            variant={
                              guestInfo.status === true
                                ? "primary"
                                : "secondary"
                            }
                            onClick={() => {
                              setGuestInfo({ ...guestInfo, status: true });
                            }}
                          >
                            <TickCircle size="16" variant="Bold" />
                            Checked
                          </Button>
                        </div>
                      </div>
                      <div className="flex flex-col w-full h-fit rounded-xl gap-[8px]">
                        <h2>Group Name</h2>
                        <div className="w-full px-[12px] py-[11px] border border-[#EFF0F2] rounded-xl">
                          <input
                            type="text"
                            name="group_name"
                            value={guestInfo.group_name}
                            className="w-full focus:outline-none"
                            onChange={(e) => handleOnChange(e)}
                          />
                        </div>
                        <p className="flex text-[#A1A7B2] text-[11px] gap-[4px] items-center">
                          <InfoCircle size="11" variant="Bold" />
                          Leave this field empty if he/she is individual
                        </p>
                      </div>
                      <div className="w-full col-span-2">
                        <TextboxSet
                          type="textarea"
                          name="note"
                          value={guestInfo.note}
                          label="Note"
                          className="w-full"
                          onChange={(e) => handleOnChange(e)}
                        />
                      </div>
                    </div>
                  }
                />
                <SectionCard
                  className={"max-w-[300px]"}
                  content={
                    <TimestampCard
                      created_at={guestInfo.created_at}
                      updated_at={guestInfo.updated_at}
                    />
                  }
                />
              </div>
            )}

            {/* Button section */}
            <div className="flex w-full gap-[8px]">
              <Button onClick={handleUpdate}>Save Changes</Button>
              <Button variant="secondary" onClick={() => navigate(GUEST_LIST)}>
                Cancel
              </Button>
            </div>

            {/* Group lists */}
            <LoadingInComponent isLoading={groupLoading}>
              {guestGroupMembers && !groupLoading && (
                <GuestListTable
                  title="Group Members"
                  data={guestGroupMembers}
                  meta={guestGroupMeta}
                  handlePageClick={handlePageClick}
                  editable
                />
              )}
            </LoadingInComponent>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default GuestEdit;
