import React, { useState } from "react";

const SearchableDropdown = ({ options }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setSearchTerm("");
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative w-64">
      <div onClick={toggleDropdown} className="border p-2 cursor-pointer">
        {selectedOption ? selectedOption : "Select an option"}
      </div>
      {isOpen && (
        <div className="absolute w-full border bg-white z-10">
          <input
            type="text"
            className="w-full p-2 border-b"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
          />
          <ul>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <li
                  key={index}
                  className="p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() => handleSelect(option)}
                >
                  {option}
                </li>
              ))
            ) : (
              <li className="p-2">No options found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
