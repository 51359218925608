import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React from "react";
import ReactPaginate from "react-paginate";
import EventListCards from "../EventListCards";
import FilterTextInput from "../FilterTextInput";
import PerPageSelector from "../PerPageSelector";

const EventListTable = ({
  eventList,
  eventMeta,
  handleRecordClick,
  handlePageClick,
  perPage,
  handlePerPageChange,
  searchTerm,
  handleSearch,
}) => {
  const currentPage = eventMeta.page > 0 ? eventMeta.page - 1 : 0;
  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput value={searchTerm} onChange={handleSearch} />
      </div>

      {/* Event data */}
      <div className="flex flex-wrap w-full h-full px-[24px] py-[12px] gap-[24px] items-center justify-center">
        {eventList.length === 0 ? (
          <div className="flex w-full h-[290px] items-center justify-center text-gray-500 text-[25px]">
            No events available
          </div>
        ) : (
          eventList.map((event, index) => (
            <EventListCards
              key={index}
              id={event.id}
              imageSource={event.image}
              title={event.name}
              date={event.date}
              onClick={handleRecordClick}
            />
          ))
        )}
      </div>

      {/* Pagination */}
      {eventMeta && (
        <div className="grid grid-cols-3 w-full border-t border-[#EFF0F2] px-[24px] py-[12px] items-center justify-between font-normal">
          {/* Page number and total */}
          <div className="grid w-full">
            <p>
              Showing page {eventMeta.page} of {eventMeta.total_pages}, Total{" "}
              {eventMeta.total}
            </p>
          </div>

          {/* Number per page selector */}
          <div className="grid w-full justify-center">
            <PerPageSelector
              perPage={perPage}
              handlePerPageChange={handlePerPageChange}
            />
          </div>

          {/* Page selector */}
          <div className="grid w-full justify-end">
            <ReactPaginate
              onPageChange={(e) => handlePageClick(e)}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={eventMeta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventListTable;
