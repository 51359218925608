import React, { createContext, useContext, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { CloseCircle, InfoCircle, TickCircle } from "iconsax-react";
import { XMarkIcon } from "@heroicons/react/24/solid";

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

// Define the Toast component
const Toast = ({ message, isVisible, setIsVisible, type }) => {
  let bdColor = "";
  let icon;

  if (type === "success") {
    bdColor = "green";
    icon = <TickCircle size="16" color="#00FF00" />;
  } else if (type === "error") {
    bdColor = "red";
    icon = <CloseCircle size="16" color="#FF0000" />;
  } else {
    bdColor = "white";
    icon = <InfoCircle size="16" color="#0000FF" />;
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <m.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 1 }}
          className={`fixed bottom-[20px] right-[20px] bg-white text-black text-[16px] p-[24px] shadow-lg rounded-[10px] flex items-center gap-[24px] z-[2000]`}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "white",
            fontSize: "16px",
            color: "black",
            padding: "24px",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            gap: "24px",
            zIndex: 2000,
          }}
        >
          <div className="flex w-full items-start gap-[12px]">
            <div className="w-fit h-fit p-[4px]">{icon}</div>
            <div>{message}</div>
            <XMarkIcon
              className="size-[16px] m-[4px] cursor-pointer"
              onClick={() => {
                setIsVisible(false);
              }}
            />
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};

export const ToastProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const toast = (msg, type) => {
    setToastType(type);
    setMessage(msg);
    setIsVisible(true);
    setTimeout(() => setIsVisible(false), 5000);
  };

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <Toast
        message={message}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        type={toastType}
      />
    </ToastContext.Provider>
  );
};
