import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import React, { useState } from "react";

const SectionCard = ({
  title,
  description,
  content,
  className,
  collapsible,
  defaultUncollapsedState = true,
}) => {
  const [uncollapsed, setUncollapsed] = useState(defaultUncollapsedState);
  return (
    <div
      className={`${className} flex flex-col w-full h-fit border border-[#EFF0F2] bg-white rounded-xl`}
    >
      {title && (
        <div
          className={`${collapsible && "hover:cursor-pointer"} ${
            uncollapsed && "border-b border-[#EFF0F2]"
          } flex w-full h-fit py-[16px] px-[24px] items-center justify-between`}
          onClick={() => {
            if (collapsible) {
              setUncollapsed(!uncollapsed);
            }
          }}
        >
          <div className="flex flex-col gap-[8px]">
            <h1 className="text-[16px]">{title}</h1>
            {description && (
              <p className="text-[14px] font-normal">{description}</p>
            )}
          </div>
          {collapsible && (
            <div>
              {uncollapsed ? <ArrowUp2 size="18" /> : <ArrowDown2 size="18" />}
            </div>
          )}
        </div>
      )}
      {uncollapsed && (
        <div className="flex flex-col w-full h-fit p-[24px]">{content}</div>
      )}
    </div>
  );
};

export default SectionCard;
