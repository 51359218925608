import React from "react";
import TextboxSet from "../TextboxSet";
import DropdownSet from "../DropdownSet";
import Button from "../Button";
import Modal from "../Modal";
import Switch from "react-switch";
import { InfoCircle } from "iconsax-react";

const CheckInForm = ({
  formData,
  toggleModal,
  handleCheckIn,
  handleFormInputChange,
  handleDropDownChange,
  handleSwitchChange,
  dietaryOption,
  tableOption,
  guestOption,
}) => {
  return (
    <Modal
      title="Add Walk-In Guest"
      toggleModal={toggleModal}
      handleSubmit={() => handleCheckIn({ redirect: false })}
      submitLabel={"Check In"}
      content={
        <div className="grid grid-cols-2 w-full gap-4 relative">
          <TextboxSet
            type="text"
            name="salutation"
            label="Title/Salutation"
            value={formData.salutation}
            onChange={handleFormInputChange}
          />
          <TextboxSet
            type="text"
            name="name"
            label="Full Name"
            required
            value={formData.name}
            onChange={handleFormInputChange}
          />
          <TextboxSet
            type="email"
            name="email"
            label="Email"
            value={formData.email}
            onChange={handleFormInputChange}
          />
          <TextboxSet
            type="tel"
            name="contact"
            label="Contact Number"
            value={formData.contact}
            onChange={handleFormInputChange}
          />
          <DropdownSet
            name="special_dietary_id"
            label="Special Dietary"
            options={dietaryOption}
            value={formData.special_dietary_id}
            onChange={handleDropDownChange}
          />
          {!formData.isReplacement && (
            <div>
              <TextboxSet
                type="text"
                name="group_name"
                label="Group Name"
                value={formData.group_name}
                onChange={handleFormInputChange}
              />
              <p className="flex text-[11px] items-center gap-[4px] font-normal text-gray-500 mt-1">
                <InfoCircle size="14" variant="Bold" />
                Leave this field empty if he/she is individual
              </p>
            </div>
          )}
          <div className="col-span-2 flex items-center mt-2">
            <Switch
              onChange={handleSwitchChange}
              checked={formData.isReplacement}
              onColor="#5C4BF5"
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <label className="ml-2 block text-sm font-medium text-gray-700">
              Check-In as replacement
            </label>
          </div>
          <div className="col-span-2 flex items-end justify-between gap-[24px]">
            {formData.isReplacement ? (
              <DropdownSet
                name={"guest_id"}
                label={"Guest Name"}
                options={guestOption}
                required
                value={formData.guest_id}
                onChange={handleDropDownChange}
              />
            ) : (
              <div className="flex w-full items-end gap-[24px]">
                <DropdownSet
                  name={"table_id"}
                  label={"Table"}
                  options={tableOption}
                  required
                  value={formData.table_id}
                  onChange={handleDropDownChange}
                />
                <Button
                  type="button"
                  variant="secondary"
                  className="w-fit whitespace-nowrap"
                  onClick={() => handleCheckIn({ redirect: true })}
                >
                  Assign Seats with Chart
                </Button>
              </div>
            )}
          </div>
          <div className="col-span-2">
            <TextboxSet
              type="textarea"
              name="note"
              label="Note"
              className="text-sm py-1 px-2"
              value={formData.note}
              onChange={handleFormInputChange}
            />
          </div>
        </div>
      }
    />
  );
};

export default CheckInForm;
