import React, { useState } from "react";
import SectionCard from "../../Components/SectionCard";
import TextboxSet from "../../Components/TextboxSet";
import ImageUpload from "../../Components/ImageUpload";
import Switch from "react-switch";
import { XMarkIcon } from "@heroicons/react/24/solid";
import LocationSet from "../../Components/LocationSet";

const EventInformation = ({ eventInfo, setEventInfo }) => {
  const handleOnChange = (e) => {
    setEventInfo({ ...eventInfo, [e.target.name]: e.target.value });
  };

  const handleImageChange = (value) => {
    setEventInfo({ ...eventInfo, image: value });
  };

  const handleRemoveImage = () => {
    setEventInfo({ ...eventInfo, image: "" });
  };

  const fileName = eventInfo.image ? eventInfo.image.split("/").pop() : "";

  return (
    <div className="flex flex-col w-full gap-[24px]">
      {/* Event info section */}
      <SectionCard
        title="Event Information"
        content={
          <div className="flex flex-col w-full gap-[24px]">
            <div className="flex w-full gap-[24px]">
              <TextboxSet
                type="text"
                name="name"
                value={eventInfo.name}
                label="Event Title"
                onChange={handleOnChange}
                required
              />
              <LocationSet
                onChange={handleOnChange}
                name="venue"
                value={eventInfo.venue}
                placeholder="Venue"
                required
              />
            </div>
            <div className="flex w-full gap-[24px]">
              <TextboxSet
                type="date"
                name="date"
                value={eventInfo.date}
                label="Date"
                onChange={handleOnChange}
                required
              />
              <TextboxSet
                type="time"
                name="time"
                value={eventInfo.time}
                label="Time"
                onChange={handleOnChange}
                required
              />
            </div>
            <div className="flex w-full gap-[24px]">
              <TextboxSet
                type="textarea"
                name="description"
                value={eventInfo.description}
                label="Event Description"
                onChange={handleOnChange}
              />
            </div>
            <div className="flex flex-col gap-[24px]">
              <div className="flex w-full gap-[16px] items-center">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor="#EFF0F2"
                  onColor="#5C4BF5"
                  width={48}
                  height={28}
                  checked={eventInfo.seat_management}
                  onChange={() => {
                    setEventInfo({
                      ...eventInfo,
                      seat_management: !eventInfo.seat_management,
                    });
                  }}
                />
                <p>Seats Management</p>
              </div>
              <div className="flex w-full gap-[16px] items-center">
                <Switch
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor="#EFF0F2"
                  onColor="#5C4BF5"
                  width={48}
                  height={28}
                  checked={eventInfo.dietary_management}
                  onChange={() => {
                    setEventInfo({
                      ...eventInfo,
                      dietary_management: !eventInfo.dietary_management,
                    });
                  }}
                />
                <p>Special Dietray Management</p>
              </div>
            </div>
          </div>
        }
      />

      {/* Event image upload section */}
      <SectionCard
        title="Event Image"
        content={
          <div className="flex flex-col w-full">
            <ImageUpload handleChange={handleImageChange} />
            {eventInfo.image && (
              <div className="relative flex flex-col w-full max-h-[200px] bg-black rounded-xl justify-center items-center">
                <div className="absolute left-[20px] top-[8px] flex items-center gap-[18px]">
                  <XMarkIcon
                    className="size-[16px] h-fit text-white hover:cursor-pointer"
                    onClick={handleRemoveImage}
                  />
                  <p className="h-fit text-white font-normal">{fileName}</p>
                </div>
                <img
                  src={eventInfo.image}
                  alt="event-image"
                  className="h-[200px] object-contain"
                />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default EventInformation;
