import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  CHECK_IN_API,
  CHECK_OUT_API,
  GET_GROUP_MEMBERS_API,
  GET_GUEST_INFO_API,
} from "../../Constant/api";
import CheckInSliderStep1 from "./CheckInSliderStep1";
import CheckInSliderStep2 from "./CheckInSliderStep2";
import { useToast } from "../Toast";

const CheckInSlider = ({ guestList, guestListRefresh, setIsOpen, guestId }) => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [guestInfo, setGuestInfo] = useState({});
  const [groupMembers, setGroupMembers] = useState([]);

  const { toast } = useToast();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredGuestList = guestList.filter((guests) => {
    const guestMatch = guests.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const groupMatch = guests.group_name
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    return guestMatch || groupMatch;
  });

  const fetchGuestInfo = (guestId) => {
    setLoading(true);
    try {
      axios
        .get(GET_GUEST_INFO_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            guestId: guestId,
            eventId: localStorage.getItem("event_id"),
          },
        })
        .then((response) => {
          setGuestInfo(response.data.data);
          fetchGroupMembers(response.data.data.id);
          setStep(2);
          setLoading(false);
        })
        .catch((error) => {
          toast(error.response.data.message, "error");
          setIsOpen(false);
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      toast(error.response.data.message, "error");
      console.error(error);
      setIsOpen(false);
      setLoading(false);
    }
  };

  const fetchGroupMembers = (guestId) => {
    setLoading(true);
    try {
      axios
        .get(GET_GROUP_MEMBERS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            guestId: guestId,
          },
        })
        .then((response) => {
          setGroupMembers(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast("Failed to fetch group members", "error");
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
      toast("Failed to fetch group members", "error");
      setLoading(false);
    }
  };

  const handleCheckIn = ({ id, type = "single" } = {}) => {
    setLoading(true);
    let guestIds = [];

    if (type === "single" && id) {
      guestIds.push(id);
    } else if (type === "all") {
      guestIds = groupMembers.map((member) => member.id);
      guestIds.push(guestInfo.id);
    }

    axios
      .put(CHECK_IN_API, guestIds, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        toast("Checked in successfully", "success");
        guestListRefresh();
        handleRecordFetch(guestInfo.id);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast("Failed to check in", "error");
      });
  };

  const handleCheckOut = ({ id, type = "single" } = {}) => {
    setLoading(true);
    let guestIds = [];

    if (type === "single" && id) {
      guestIds.push(id);
    } else if (type === "all") {
      guestIds = groupMembers.map((member) => member.id);
      guestIds.push(guestInfo.id);
    }

    axios
      .put(CHECK_OUT_API, guestIds, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        toast("Checked out successfully", "success");
        guestListRefresh();
        handleRecordFetch(guestInfo.id);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast("Failed to check out", "error");
      });
  };

  const handleRecordFetch = (guest_id) => {
    fetchGuestInfo(guest_id);
  };

  useEffect(() => {
    if (guestId) {
      fetchGuestInfo(guestId);
    }
  }, []);

  return (
    <div className="fixed flex w-full h-screen bg-black bg-opacity-60">
      <div className="w-full h-screen" onClick={() => setIsOpen(false)} />

      {step === 1 ? (
        <CheckInSliderStep1
          loading={loading}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          setIsOpen={setIsOpen}
          filteredGuestList={filteredGuestList}
          handleRecordClick={(guest_id) => handleRecordFetch(guest_id)}
        />
      ) : (
        step === 2 && (
          <CheckInSliderStep2
            guestInfo={guestInfo}
            groupMembers={groupMembers}
            loading={loading}
            setIsOpen={setIsOpen}
            setStep={setStep}
            handleCheckIn={handleCheckIn}
            handleCheckOut={handleCheckOut}
          />
        )
      )}
    </div>
  );
};

export default CheckInSlider;
