import React, { useState, useEffect } from "react";
import Button from "./Button";
import {
  ClipboardImport,
  Coffee,
  Document,
  Element3,
  ElementPlus,
  Grid4,
  MenuBoard,
  Profile2User,
  Sms,
} from "iconsax-react";
import Grouping from "./Grouping";
import {
  CHECKIN,
  DASHBOARD,
  DIETARY_EDIT,
  DIETARY_MANAGEMENT,
  EVENT_INFO,
  GUEST_ADD,
  GUEST_EDIT,
  GUEST_LIST,
  GUEST_BULK_PREVIEW,
  GUEST_LIST_IMPORT_LOG,
  MESSAGE_BLAST,
  MESSAGE_BLAST_ADD,
  MESSAGE_BLAST_EDIT,
  REPORT_OVERVIEW,
  SEATS_MANAGEMENT,
  GUEST_LIST_IMPORT_LOG_DETAILS,
} from "../Router/path";
import { useNavigate, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [seat, setSeat] = useState(localStorage.getItem("seat") === "true");
  const [dietary, setDietary] = useState(
    localStorage.getItem("dietary") === "true"
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleStorageChange = () => {
      setSeat(localStorage.getItem("seat") === "true");
      setDietary(localStorage.getItem("dietary") === "true");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [location.pathname]);

  const setupGroup = [
    {
      name: "event",
      icon: <Grid4 size="18" variant="Bold" />,
      title: "Event Page",
      navigate: [EVENT_INFO],
    },
    {
      name: "guest",
      icon: <Profile2User size="18" variant="Bold" />,
      title: "Guest List",
      navigate: [GUEST_LIST, GUEST_ADD, GUEST_EDIT, GUEST_BULK_PREVIEW],
    },
    {
      name: "message",
      icon: <Sms size="18" variant="Bold" />,
      title: "Message Blast",
      navigate: [MESSAGE_BLAST, MESSAGE_BLAST_ADD, MESSAGE_BLAST_EDIT],
    },
  ];

  const reportGrouping = [
    {
      name: "report",
      icon: <Document size="18" variant="Bold" />,
      title: "Report",
      navigate: [REPORT_OVERVIEW],
      condition: true,
    },
    {
      name: "seat",
      icon: <ElementPlus size="18" variant="Bold" />,
      title: "Seat Management",
      navigate: [SEATS_MANAGEMENT],
      condition: seat,
    },
    {
      name: "dietary",
      icon: <Coffee size="18" variant="Bold" />,
      title: "Special Dietary Management",
      navigate: [DIETARY_MANAGEMENT, DIETARY_EDIT],
      condition: dietary,
    },
    {
      name: "guestListImportLog",
      icon: <ClipboardImport size="18" variant="Bold" />,
      title: "Guest List Import Log",
      navigate: [GUEST_LIST_IMPORT_LOG, GUEST_LIST_IMPORT_LOG_DETAILS],
      condition: true,
    },
  ];

  return (
    <div className="flex absolute left-0 z-10 w-full lg:sticky lg:w-fit bg-black bg-opacity-30">
      <div className="flex flex-col grow min-w-[320px] max-w-[320px] h-[calc(100vh-80px)] bg-white border-r border-[#EFF0F2] py-[24px] px-[16px] gap-[24px] no-scrollbar overflow-y-auto no-scrollbar">
        <div>
          <Button
            name="dashboard"
            variant={
              location.pathname === DASHBOARD ? "sidebar_active" : "sidebar"
            }
            className="w-full text-start"
            onClick={() => {
              navigate(DASHBOARD);
            }}
          >
            <Element3 size="18" variant="Bold" />
            Dashboard
          </Button>
        </div>

        {/* Setup grouping */}
        <Grouping name="Setup">
          <div className="flex flex-col gap-[8px]">
            {setupGroup.map((item, index) => (
              <Button
                key={index}
                name={item.name}
                variant={
                  item.navigate.some((path) => location.pathname.includes(path))
                    ? "sidebar_active"
                    : "sidebar"
                }
                className="w-full text-start"
                onClick={() => {
                  navigate(item.navigate[0]);
                }}
              >
                {item.icon}
                {item.title}
              </Button>
            ))}
          </div>
        </Grouping>

        {/* Event Day Grouping */}
        <Grouping name="Event Day">
          <div className="flex flex-col gap-[8px]">
            <Button
              name="checkIn"
              variant={
                location.pathname === CHECKIN ? "sidebar_active" : "sidebar"
              }
              onClick={() => {
                navigate(CHECKIN);
              }}
            >
              <MenuBoard size="18" variant="Bold" />
              Check In
            </Button>
          </div>
        </Grouping>

        {/* Reports & Management Grouping */}
        <Grouping name="Reports & Manage">
          <div className="flex flex-col gap-[8px]">
            {reportGrouping.map(
              (item, index) =>
                item.condition && (
                  <Button
                    key={index}
                    name={item.name}
                    variant={
                      item.navigate.some((path) =>
                        location.pathname.includes(path)
                      )
                        ? "sidebar_active"
                        : "sidebar"
                    }
                    className="w-full text-start"
                    onClick={() => {
                      navigate(item.navigate[0]);
                    }}
                  >
                    {item.icon}
                    {item.title}
                  </Button>
                )
            )}
          </div>
        </Grouping>
      </div>
    </div>
  );
};

export default Sidebar;
