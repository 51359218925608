import React, { useState } from "react";
import FilterTextInput from "../../Components/FilterTextInput";
import Button from "../../Components/Button";
import { ArrowSwapHorizontal } from "iconsax-react";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
} from "@heroicons/react/24/solid";
import TableContainer from "../../Components/SeatingChart/TableContainer";
import axios from "axios";
import { CREATE_TABLE_API } from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import Modal from "../../Components/Modal";
import TextboxSet from "../../Components/TextboxSet";
import Counter from "../../Components/Counter";
import LoadingInComponent from "../../Components/LoadingInComponent";

const NormalMode = ({ tableData, handleRefresh, setMode }) => {
  const [collapseAll, setCollapseAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    pax: 10,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const { toast } = useToast();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleCollapseAll = () => {
    setCollapseAll((prev) => !prev);
  };

  const handleChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const filteredTableData = tableData
    .filter((table) => table.table_id !== -2)
    .filter((table) => {
      const guestMatch = table.guest_list.some((guest) =>
        guest.guest_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      const tableMatch = table.table_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

      return tableMatch || guestMatch;
    });

  const handleSubmit = () => {
    setLoading(true);
    try {
      axios
        .post(
          CREATE_TABLE_API,
          { ...formData, event_id: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          toggleModal();
          setLoading(false);
          handleRefresh();
          toast("Table created successfully", "success");
          setFormData({
            name: "",
            description: "",
            pax: 1,
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to create table", "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to create table", "error");
    }
  };

  return (
    <LoadingInComponent isLoading={loading}>
      <div className="flex flex-col w-full h-full gap-[24px]">
        {showModal && (
          <Modal
            title="Add Table"
            toggleModal={toggleModal}
            handleSubmit={handleSubmit}
            content={
              <div className="flex flex-col w-full h-full gap-[24px]">
                <TextboxSet
                  label="Table Name"
                  name="name"
                  type="text"
                  required
                  onChange={(e) => handleChange(e)}
                />
                <Counter formData={formData} setFormData={setFormData} />
                <TextboxSet
                  label="Table Description"
                  name="description"
                  type="textarea"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            }
          />
        )}
        {/* Top part buttons and search filter */}
        <div className="flex w-full justify-between items-center">
          <FilterTextInput
            placeholder="Search tables or guests"
            className="w-[300px]"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="flex gap-[8px]">
            {/* <Button variant="secondary" onClick={() => setMode("swap")}>
              <ArrowSwapHorizontal size="16" />
              Swap Mode
            </Button> */}
            <Button variant="secondary" onClick={handleCollapseAll}>
              {collapseAll ? (
                <ArrowsPointingOutIcon className="size-[8px] lg:size-[16px]" />
              ) : (
                <ArrowsPointingInIcon className="size-[8px] lg:size-[16px]" />
              )}
              {collapseAll ? "Expand" : "Collapse"}
            </Button>
            <Button onClick={toggleModal}>Add Table</Button>
          </div>
        </div>

        {/* Table list with guests */}
        <div className="h-full">
          {tableData && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-[12px] lg:gap-[24px]">
              {filteredTableData.map((table, index) => (
                <TableContainer
                  data={table}
                  key={table.table_id}
                  index={index}
                  collapseAll={collapseAll}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </LoadingInComponent>
  );
};

export default NormalMode;
