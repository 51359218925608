import React, { useState } from "react";
import Button from "../../Components/Button";
import FilterTextInput from "../../Components/FilterTextInput";
import { Clock, CloseCircle, TickCircle } from "iconsax-react";
import Modal from "../../Components/Modal";
import DropdownSet from "../../Components/DropdownSet";
import axios from "axios";
import { ASSIGN_GUEST_API, UNSEAT_GUEST_API } from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import { LoadingOverlay } from "../../Components/LoadingOverlay";
import { headers } from "next/dist/client/components/headers";

const SeatManagementEditTable = ({
  tableId,
  data,
  guestList,
  fetchTableDetail,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();

  const { toast } = useToast();

  const handleOnChange = (name, value) => {
    setValue(value);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleAddGuest = () => {
    if (!value) {
      toast("Please select a guest", "error");
    } else {
      setLoading(true);
      try {
        axios
          .post(
            ASSIGN_GUEST_API,
            {
              tableId: tableId,
              guestId: value,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            toggleModal();
            toast("Table assigned successfully", "success");
            fetchTableDetail();
          })
          .catch((error) => {
            setLoading(false);
            toast(error.response.data.message, "error");
            console.error(error);
          });
      } catch (error) {
        setLoading(false);
        toast("Failed to assign table", "error");
        console.error(error);
      }
    }
  };

  const handleUnseatGuest = (guestId) => {
    try {
      setLoading(true);
      axios
        .put(
          UNSEAT_GUEST_API,
          { guestId: guestId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          toast("Guest unseated successfully", "success");
          fetchTableDetail();
        })
        .catch((error) => {
          setLoading(false);
          toast(error.response.data.message, "error");
          console.error(error);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to unseat guest", "error");
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {loading && <LoadingOverlay />}
      {showModal && (
        <Modal
          title="Add Guest"
          toggleModal={toggleModal}
          handleSubmit={handleAddGuest}
          submitLabel="Add Guest"
          content={
            <div className="flex flex-col w-full gap-[24px]">
              <DropdownSet
                value={value}
                options={guestList}
                label="Guest"
                name="guestId"
                onChange={(name, value) => handleOnChange(name, value)}
              />
            </div>
          }
        />
      )}
      {/* Title */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[16px] justify-between items-center">
        <h2>Seats Management</h2>
        <Button onClick={toggleModal}>Add Guest</Button>
      </div>

      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput />
      </div>

      {/* Table data */}
      <div className="flex w-full min-h-[290px] gap-[24px] no-scrollbar overflow-x-auto">
        {!data || data.length === 0 ? (
          <div className="flex w-full items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
            No table found
          </div>
        ) : (
          <table className="w-full h-full">
            {/* Table Header */}
            <tr className="w-full bg-[#F7F8F9]">
              <th className="text-start px-[24px] py-[16px]">Guest</th>
              <th className="text-start px-[24px] py-[16px]">Group</th>
              <th className="text-start px-[24px] py-[16px]">
                Special Dietary
              </th>
              <th className="text-start px-[24px] py-[16px]">
                Check-In Status
              </th>
              <th className="text-start px-[24px] py-[16px]"></th>
            </tr>

            {/* Table Body */}
            {data &&
              data.map((item) => (
                <tr
                  key={item.id}
                  className="w-full font-normal border-y border-[#EFF0F2]"
                >
                  <td className="px-[24px] py-[16px]">
                    {item.guest_name ?? "-"}
                  </td>
                  <td className="px-[24px] py-[16px]">
                    {item.group_name ?? "-"}
                  </td>
                  <td className="px-[24px] py-[16px]">{item.dietary ?? "-"}</td>
                  <td className="px-[24px] py-[16px] max-w-[200px] truncate">
                    {item.guest_status === true ? (
                      <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#F4FCF7] border border-[#E9F9EF] text-[#22C55E]">
                        <TickCircle size="16" variant="Bold" />
                        Checked
                      </div>
                    ) : (
                      <div className="flex w-fit rounded-md px-[8px] gap-[4px] items-center bg-[#FFFBF3] border border-[#FEF6E6] text-[#F0A009]">
                        <Clock size="16" variant="Bold" />
                        Pending
                      </div>
                    )}
                  </td>
                  <td className="flex px-[24px] py-[16px] h-full gap-[12px] items-center justify-end">
                    <span
                      className="flex text-red-500 text-[14px] items-center hover:underline cursor-pointer"
                      onClick={() => handleUnseatGuest(item.id)}
                    >
                      <CloseCircle size="15" variant="Bold" />
                      Unseat
                    </span>
                  </td>
                </tr>
              ))}
          </table>
        )}
      </div>
    </div>
  );
};

export default SeatManagementEditTable;
