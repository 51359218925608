import React, { useState } from "react";
import FilterTextInput from "../FilterTextInput";
import { ArrowLeft2, ArrowRight2, CloseCircle, Edit2 } from "iconsax-react";
import { DIETARY_EDIT } from "../../Router/path";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import PerPageSelector from "../PerPageSelector";

const DietaryTable = ({
  data,
  meta,
  handlePageClick,
  searchTerm,
  handleSearch,
  perPage,
  handlePerPageChange,
}) => {
  const currentPage = meta ? meta.page - 1 : 0;
  const navigate = useNavigate();

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      <div className="flex flex-col w-full h-full">
        {/* Filters */}
        <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
          <FilterTextInput
            value={searchTerm}
            onChange={(e) => handleSearch(e)}
          />
        </div>

        {/* Table data */}
        <div className="flex w-full h-full gap-[24px] no-scrollbar overflow-x-auto">
          {!data || data.length === 0 ? (
            <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
              No dietary found
            </div>
          ) : (
            <table className="w-full h-full">
              {/* Table Header */}
              <tr className="w-full bg-[#F7F8F9]">
                <th className="text-start px-[24px] py-[16px]">
                  Special Dietary
                </th>
                <th className="text-start px-[24px] py-[16px]">Count</th>
                <th className="text-start px-[24px] py-[16px]"></th>
              </tr>

              {/* Table Body */}
              {data &&
                data.map((item) => (
                  <tr
                    key={item.id}
                    className="w-full font-normal border-b border-[#EFF0F2] last:border-none"
                  >
                    <td className="px-[24px] py-[16px]">{item.name ?? "-"}</td>
                    <td className="px-[24px] py-[16px]">{item.count ?? "0"}</td>
                    <td className="flex px-[24px] py-[16px] h-full gap-[12px] items-center justify-end">
                      <span
                        className="flex text-[#5C4BF5] text-[14px] items-center hover:underline cursor-pointer"
                        onClick={() =>
                          navigate(DIETARY_EDIT, { state: item.id })
                        }
                      >
                        <Edit2 size="15" variant="Bold" />
                        Edit
                      </span>
                    </td>
                  </tr>
                ))}
            </table>
          )}
        </div>

        {/* Pagination */}
        {meta && (
          <div className="grid grid-cols-3 w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center justify-between font-normal">
            {/* Page number and total */}
            <div className="grid w-full">
              Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
            </div>

            {/* Number per page selector */}
            <div className="grid w-full justify-center">
              <PerPageSelector
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>

            {/* Page selector */}
            <div className="grid w-full justify-end">
              <ReactPaginate
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                pageCount={meta.total_pages}
                forcePage={currentPage}
                breakLabel="..."
                previousLabel={<ArrowLeft2 size="16px" />}
                nextLabel={<ArrowRight2 size="16px" />}
                nextClassName="paginator-page-btn"
                previousClassName="paginator-page-btn"
                containerClassName="flex rounded-xl border font-medium"
                activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
                pageClassName="paginator-page-btn border-x"
                breakClassName="paginator-page-btn border-x"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DietaryTable;
