import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import WidgetCard from "../../Components/WidgetCard";
import SeatManagementTable from "../../Components/Tables/SeatManagementTable";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import {
  GET_CHECK_IN_WIDGET_API,
  GET_TABLE_DETAIL_LIST_API,
} from "../../Constant/api";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import { EVENT_CHECK_IN_PAGE } from "../../Router/path";

const CheckInList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableDetailData, setTableDetailData] = useState();
  const [tableDetailMeta, setTableDetailMeta] = useState();
  const [widgetData, setWidgetData] = useState({});
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();
  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchCheckInList();
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchCheckInList(1, e.target.value);
  };

  const handlePageClick = (e) => {
    fetchCheckInList(e.selected + 1);
  };

  const fetchWidgetData = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_CHECK_IN_WIDGET_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setLoading(false);
          setWidgetData(response.data.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to fetch widget data", "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to fetch widget data", "error");
    }
  };

  const fetchCheckInList = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_TABLE_DETAIL_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            page: page,
            perPage: perPageValue,
            search: searchTerm,
          },
        })
        .then((response) => {
          setLoading(false);
          setTableDetailData(response.data.data);
          setTableDetailMeta(response.data.meta.pagination);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to fetch check-in list", "error");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to fetch check-in list", "error");
    }
  };

  useEffect(() => {
    fetchWidgetData();
    fetchCheckInList();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Check In</h1>
              <Button onClick={() => navigate(EVENT_CHECK_IN_PAGE)}>
                Open Event Page
              </Button>
            </div>

            {/* Widgets */}
            {widgetData && (
              <div className="flex w-full gap-[24px]">
                <WidgetCard
                  name="Total Guests"
                  data={widgetData.total_guests}
                />
                <WidgetCard
                  name="Total Checked In Guests"
                  data={widgetData.total_checked_in_guests}
                />
                <WidgetCard
                  name="Total Not Checked In Guests"
                  data={widgetData.total_not_checked_in_guests}
                />
              </div>
            )}

            {/* Table */}
            {localStorage.getItem("seat") &&
              localStorage.getItem("seat") === "true" && (
                <div>
                  <SeatManagementTable
                    data={tableDetailData}
                    meta={tableDetailMeta}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    handlePageClick={handlePageClick}
                    handlePerPageChange={handlePerPageChange}
                    perPage={perPage}
                  />
                </div>
              )}
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default CheckInList;
