import { HambergerMenu } from "iconsax-react";
import React from "react";
import Button from "./Button";
import axios from "axios";
import { LOGOUT_API } from "../Constant/api";
import { useNavigate } from "react-router-dom";
import { useToast } from "./Toast";
import { EVENT_LIST } from "../Router/path";

const Header = ({ noSidebar = false, toggleSidebar }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleLogout = () => {
    axios.post(LOGOUT_API).then((response) => {
      if (response.data.success) {
        localStorage.removeItem("bearer_token");
        localStorage.removeItem("event_id");
        localStorage.removeItem("dietary");
        localStorage.removeItem("seat");
        navigate("/");
        toast("Logout Success", "success");
      } else {
        toast(response.data.message, "error");
      }
    });
  };

  return (
    <div className="flex sticky top-0 z-10 w-full h-[80px] max-h-[80px] items-center justify-between bg-white px-[32px] border-b border-[#EFF0F2]">
      <div className="flex w-full items-center justify-start gap-[16px]">
        {!noSidebar && (
          <Button variant="icon" onClick={toggleSidebar}>
            <HambergerMenu size="14" />
          </Button>
        )}
        <img
          src="images/logo.png"
          className="w-[118px] h-[32px] hover:cursor-pointer"
          alt="logo"
          onClick={() => {
            localStorage.removeItem("event_id");
            localStorage.removeItem("dietary");
            localStorage.removeItem("seat");
            navigate(EVENT_LIST);
          }}
        />
      </div>
      <div className="flex w-full items-center justify-end">
        <div className="">
          <Button onClick={handleLogout}>Logout</Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
