import { XMarkIcon } from "@heroicons/react/24/solid";
import React from "react";
import FilterTextInput from "../FilterTextInput";
import LoadingInComponent from "../LoadingInComponent";
import GuestCard from "./GuestCard";

const CheckInSliderStep1 = ({
  loading,
  searchTerm,
  setIsOpen,
  filteredGuestList,
  handleSearch,
  handleRecordClick,
}) => {
  return (
    <div className="absolute right-0 top-0 flex flex-col bg-white w-6/12 lg:w-4/12 h-screen no-scrollbar overflow-y-auto">
      <LoadingInComponent isLoading={loading}>
        <div className="flex flex-col w-full p-[24px] gap-[24px] border-b border-[#EFF0F2]">
          <div className="flex w-full justify-between">
            <div>
              <h2 className="w-full text-[17px]">Guest List</h2>
              <p className="text-[14px] text-[#636C7E] font-normal">
                Search by guest name or group name.
              </p>
            </div>
            <XMarkIcon
              className="size-[24px] hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <FilterTextInput value={searchTerm} onChange={handleSearch} />
        </div>

        {filteredGuestList && (
          <div className="flex flex-col w-full p-[24px] gap-[8px]">
            {filteredGuestList.map((guest, index) => (
              <GuestCard
                key={index}
                data={guest}
                onClick={() => handleRecordClick(guest.id)}
              />
            ))}
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default CheckInSliderStep1;
