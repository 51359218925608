import React, { useState } from "react";
import Button from "./Button";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";

const Grouping = ({ name, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex w-full flex-col gap-[4px]">
      <Button
        variant="sidebar_grouping"
        className="w-full text-start"
        onClick={handleToggle}
      >
        {name}
        {isOpen ? (
          <ArrowUp2 size="18" color="#697689" />
        ) : (
          <ArrowDown2 size="18" color="#697689" />
        )}
      </Button>
      {isOpen && children ? children : null}
    </div>
  );
};

export default Grouping;
