import React from "react";
import Switch from "react-switch";

const TemplateCards = ({
  id,
  imageSource,
  templateName,
  checked,
  onChange,
}) => {
  return (
    <div className="flex flex-col w-fit h-fit gap-[12px] items-center justify-center">
      <img src={imageSource} alt="template" className="w-[200px] h-[133px]" />
      <div className="flex flex-col items-center justify-center">
        <div>
          <h2>{templateName}</h2>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Switch
            id={id}
            checked={checked}
            onChange={onChange}
            checkedIcon={false}
            uncheckedIcon={false}
            offColor="#EFF0F2"
            onColor="#5C4BF5"
            width={21}
            height={14}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateCards;
