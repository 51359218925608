import React from "react";

const EventListCards = ({ id, imageSource, title, date, onClick }) => {
  return (
    <div
      className="flex flex-col min-w-[278px] max-h-fit p-[16px] gap-[16px] border border-[#EFF0F2] rounded-md hover:bg-[#F7F8F9] hover:cursor-pointer"
      onClick={() => onClick(id)}
    >
      <div className="aspect-[3/2] flex w-full bg-black rounded-md justify-center">
        <img
          src={imageSource ?? "https://placehold.co/600x400?text=No+Image"}
          alt="imageSource"
          className="object-contain rounded-md max-h-[164px]"
        />
      </div>
      <div className="flex flex-col w-full">
        <h1 className="font-md text-[14px]">{title}</h1>
        <p className="font-md text-[11px] text-[#636C7E]">{date}</p>
      </div>
    </div>
  );
};

export default EventListCards;
