import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  CONFIRM_IMPORT_API,
  FETCH_TEMP_GUEST_LIST_API,
} from "../../Constant/api";
import Button from "../../Components/Button";
import { GUEST_LIST } from "../../Router/path";
import GuestUploadPreviewTable from "../../Components/Tables/GuestUploadPreviewTable";
import { useToast } from "../../Components/Toast";
import { Warning2 } from "iconsax-react";

const GuestBulkUploadPreview = () => {
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState();
  const [missingRows, setMissingRows] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const { toast } = useToast();
  const { state } = useLocation();
  const navigate = useNavigate();

  const filteredGuestList = (guestList || []).filter((guests) => {
    const guestMatch = guests.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    const groupMatch = guests.group_name
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    const emailMatch = guests.email
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase());

    const contactMatch =
      typeof guests.contact === "string"
        ? guests.contact.toLowerCase().includes(searchTerm.toLowerCase())
        : false;

    return guestMatch || contactMatch || emailMatch || contactMatch;
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const fetchTempGuestList = async () => {
    setLoading(true);
    try {
      axios
        .get(FETCH_TEMP_GUEST_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { url: state.url },
        })
        .then((res) => {
          setLoading(false);
          setGuestList(res.data.data.data);
          setMissingRows(res.data.data.missing);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch data", "error");
      console.log(error);
    }
  };

  const handleConfirmImport = () => {
    setLoading(true);
    try {
      axios
        .post(
          CONFIRM_IMPORT_API,
          { filePath: state.url, eventId: localStorage.getItem("event_id") },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          toast("Guest list imported successfully", "success");
          navigate(GUEST_LIST);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast("Failed to import", "error");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast("Failed to import", "error");
    }
  };

  useEffect(() => {
    fetchTempGuestList();
  }, [state]);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Import Guest List Preview</h1>
            </div>

            {/* Warnings */}
            {missingRows && missingRows > 0 && (
              <div className="flex w-full px-[24px] py-[16px] gap-[8px] border border-[#EFF0F2] rounded-xl items-center bg-white">
                <Warning2 size="24" variant="Outline" />
                <p className="font-normal">
                  <span className="font-medium text-[#F0A009]">{`${missingRows} row(s) `}</span>
                  could not be imported due to missing required fields.
                </p>
              </div>
            )}

            {/* Guest list table */}
            <div>
              <GuestUploadPreviewTable
                searchable
                data={filteredGuestList}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                // meta={guestListMeta}
                // handlePageClick={handlePageClick}
              />
            </div>

            <div className="flex gap-[8px]">
              <Button onClick={handleConfirmImport}>Confirm Import</Button>
              <Button variant="secondary" onClick={() => navigate(GUEST_LIST)}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default GuestBulkUploadPreview;
