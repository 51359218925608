import React, { useState, useEffect } from "react";
import Button from "../../Components/Button";
import { Designtools, Edit } from "iconsax-react";
import EventInformation from "./EventInformation";
import PageTemplate from "./PageTemplate";
import axios from "axios";
import {
  GET_EVENT_INFO_API,
  GET_TEMPLATE_LIST_API,
  UPDATE_EVENT_INFO_API,
} from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import LoadingInComponent from "../../Components/LoadingInComponent";
import { useLocation } from "react-router-dom";
import { LoadingOverlay } from "../../Components/LoadingOverlay";

const EventManagement = () => {
  const [activeTab, setActiveTab] = useState("Event Information");
  const [eventInfo, setEventInfo] = useState({});
  const [templateList, setTemplateList] = useState([]);
  const [templateListMeta, setTemplateListMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [overlayLoading, setOverlayLoading] = useState(false);

  const { toast } = useToast();

  const fetchEventInfo = () => {
    setLoading(true);
    try {
      axios
        .get(GET_EVENT_INFO_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setEventInfo(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Failed to fetch event info", error);
          toast("Failed to fetch event info", "error");
        });
    } catch (error) {
      setLoading(false);
      console.log("Failed to fetch event info", error);
      toast("Failed to fetch event info", "error");
    }
  };

  const fetchTemplateList = (page = 1) => {
    setLoading(true);
    try {
      axios
        .get(GET_TEMPLATE_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { page: page },
        })
        .then((response) => {
          setTemplateList(response.data.data);
          setTemplateListMeta(response.data.meta.pagination);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("Failed to fetch template list", error);
          toast("Failed to fetch template list", "error");
        });
    } catch (error) {
      setLoading(false);
      console.log("Failed to fetch template list", error);
      toast("Failed to fetch template list", "error");
    }
  };

  const handleUpdate = () => {
    if (
      eventInfo.name !== "" &&
      eventInfo.venue !== "" &&
      eventInfo.date !== "" &&
      eventInfo.time !== ""
    ) {
      setLoading(true);
      setOverlayLoading(true);
      try {
        axios
          .put(UPDATE_EVENT_INFO_API, eventInfo, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
            params: { eventId: localStorage.getItem("event_id") },
          })
          .then((response) => {
            setLoading(false);
            localStorage.setItem("seat", eventInfo.seat_management);
            localStorage.setItem("dietary", eventInfo.dietary_management);
            window.location.reload();
          })
          .catch((error) => {
            setLoading(false);
            console.log("Failed to update event info", error);
            toast("Failed to update event info", "error");
          });
      } catch (error) {
        setLoading(false);
        console.log("Failed to update event info", error);
        toast("Failed to update event info", "error");
      }
    } else {
      toast("Please fill in all required fields", "error");
    }
  };

  const handlePageClick = (e) => {
    fetchTemplateList(e.selected + 1);
  };

  useEffect(() => {
    fetchEventInfo();
    fetchTemplateList();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {overlayLoading && <LoadingOverlay />}
      {eventInfo && (
        <LoadingInComponent isLoading={loading}>
          {!loading && (
            <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
              {/* Header section */}
              <div>
                {/* Title and buttons */}
                <div className="flex w-full justify-between items-center">
                  <h1 className="text-[29px]">{activeTab}</h1>
                  <div className="flex w-fit items-center gap-[8px]">
                    {/* <Button variant="secondary">Preview</Button> */}
                    <Button variant="primary" onClick={handleUpdate}>
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>

              {/* Event information section */}
              <div className="flex w-full h-full gap-[24px]">
                {/* Side bar */}
                <div className="flex flex-col w-[288px] h-fit gap-[8px]">
                  <Button
                    variant={
                      activeTab === "Event Information"
                        ? "sidebar_active"
                        : "sidebar"
                    }
                    className="w-full"
                    onClick={() => setActiveTab("Event Information")}
                  >
                    <Edit size="14" variant="Bold" />
                    Event Information
                  </Button>
                  {/* <Button
                    variant={
                      activeTab === "Page Template"
                        ? "sidebar_active"
                        : "sidebar"
                    }
                    className="w-full"
                    onClick={() => setActiveTab("Page Template")}
                  >
                    <Designtools size="14" variant="Bold" />
                    Page template
                  </Button> */}
                </div>

                {/* Active tabs */}
                <div className="w-full">
                  {activeTab === "Event Information" && (
                    <EventInformation
                      eventInfo={eventInfo}
                      setEventInfo={setEventInfo}
                    />
                  )}
                  {activeTab === "Page Template" && (
                    <PageTemplate
                      handlePageClick={handlePageClick}
                      templateList={templateList}
                      templateListMeta={templateListMeta}
                      eventInfo={eventInfo}
                      setEventInfo={setEventInfo}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </LoadingInComponent>
      )}
    </div>
  );
};

export default EventManagement;
