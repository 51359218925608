import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import Button from "../../Components/Button";
import { Add, Send2, Whatsapp } from "iconsax-react";
import MessageBlastTable from "./MessageBlastTable";
import { GET_EMAIL_TEMPLATE_LIST_API } from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { MESSAGE_BLAST_ADD } from "../../Router/path";

const MessageBlast = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Email Template");
  const [emailData, setEmailData] = useState();
  const [emailMeta, setEmailMeta] = useState();
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchEmailTemplate();
    }
  };

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchEmailTemplate(1, e.target.value);
  };

  const handlePageClick = (e) => {
    fetchEmailTemplate(e.selected + 1);
  };

  const fetchEmailTemplate = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_EMAIL_TEMPLATE_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            eventId: localStorage.getItem("event_id"),
            page: page,
            search: searchTerm,
            perPage: perPageValue,
          },
        })
        .then((response) => {
          setEmailData(response.data.data);
          setEmailMeta(response.data.meta.pagination);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch email template", "error");
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
      toast("Failed to fetch email template", "error");
    }
  };

  useEffect(() => {
    fetchEmailTemplate();
    if (location.state?.type) {
      setCurrentTab(location.state.type);
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">{currentTab}</h1>
              <Button
                onClick={() =>
                  navigate(MESSAGE_BLAST_ADD, { state: { type: currentTab } })
                }
              >
                <Add size="16" variant="Outline" />
                Add template
              </Button>
            </div>

            {/* Event information section */}
            <div className="flex w-full h-full gap-[24px]">
              {/* Side bar */}
              {/* <div className="flex flex-col w-[288px] h-fit gap-[8px]">
                <Button
                  variant={
                    currentTab === "Email Template"
                      ? "sidebar_active"
                      : "sidebar"
                  }
                  className="w-full"
                  onClick={() => setCurrentTab("Email Template")}
                >
                  <Send2 size="14" variant="Bold" />
                  Email Template
                </Button>
                <Button
                  variant={
                    currentTab === "WhatsApp Template"
                      ? "sidebar_active"
                      : "sidebar"
                  }
                  className="w-full"
                  onClick={() => setCurrentTab("WhatsApp Template")}
                >
                  <Whatsapp size="14" variant="Bold" />
                  WhatsApp template
                </Button>
              </div> */}

              {/* Active tabs */}
              <div className="w-full">
                {/* {currentTab === "Email Template" && emailData && emailMeta && (
                  {emailData && emailMeta && (
                  <MessageBlastTable
                    data={emailData}
                    meta={emailMeta}
                    currentTab={currentTab}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    handlePageClick={handlePageClick}
                    perPage={perPage}
                    handlePerPageChange={handlePerPageChange}
                  />
                )}
                )}
                {currentTab === "WhatsApp Template" && <div></div>} */}

                {/* Email */}
                {emailData && emailMeta && (
                  <MessageBlastTable
                    data={emailData}
                    meta={emailMeta}
                    currentTab={currentTab}
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                    handlePageClick={handlePageClick}
                    setLoading={setLoading}
                    perPage={perPage}
                    handlePerPageChange={handlePerPageChange}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default MessageBlast;
