import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  CHECKIN,
  DASHBOARD,
  DIETARY_EDIT,
  DIETARY_MANAGEMENT,
  EVENT_INFO,
  EVENT_LIST,
  EVENT_CHECK_IN_PAGE,
  GUEST_ADD,
  GUEST_BULK_PREVIEW,
  GUEST_EDIT,
  GUEST_LIST,
  GUEST_LIST_IMPORT_LOG,
  GUEST_LIST_IMPORT_LOG_DETAILS,
  LOGIN,
  MESSAGE_BLAST,
  MESSAGE_BLAST_ADD,
  MESSAGE_BLAST_EDIT,
  REPORT_OVERVIEW,
  SEATING_CHART,
  SEATS_MANAGEMENT,
  SEATS_MANAGEMENT_EDIT,
} from "./path";
import EventList from "../Pages/EventList";
import Login from "../Pages/Login";
import EventPage from "../Pages/Event/EventPage";
import Dashboard from "../Pages/Dashboard";
import GuestListPage from "../Pages/Guests/GuestListPage";
import GuestAdd from "../Pages/Guests/GuestAdd";
import GuestEdit from "../Pages/Guests/GuestEdit";
import CheckInList from "../Pages/CheckIn/CheckInList";
import ReportOverview from "../Pages/ReportOverview";
import MainLayout from "../Components/MainLayout";
import MessageBlast from "../Pages/MessageBlast/MessageBlast";
import MessageBlastAdd from "../Pages/MessageBlast/MessageBlastAdd";
import MessageBlastEdit from "../Pages/MessageBlast/MessageBlastEdit";
import SeatManagementList from "../Pages/SeatManagement/SeatManagementList";
import SeatManagementEdit from "../Pages/SeatManagement/SeatManagementEdit";
import DietaryList from "../Pages/DietaryManagement/DietaryList";
import DietaryEdit from "../Pages/DietaryManagement/DietaryEdit";
import SeatingChart from "../Pages/SeatingChart/SeatingChart";
import EventCheckInPage from "../Pages/CheckIn/EventCheckInPage";
import GuestBulkUploadPreview from "../Pages/Guests/GuestBulkUploadPreview";
import ImportLogList from "../Pages/ImportLog/ImportLogList";
import ImportLogDetails from "../Pages/ImportLog/ImportLogDetails";

export default function Router() {
  const isAuthenticated = () => !!localStorage.getItem("bearer_token");
  const hasEventSelected = () => !!localStorage.getItem("event_id");

  const routes = [
    {
      path: LOGIN,
      element: isAuthenticated() ? <Navigate to={EVENT_LIST} /> : <Login />,
    },
    {
      path: EVENT_LIST,
      element: isAuthenticated() ? <EventList /> : <Navigate to={LOGIN} />,
    },
    {
      path: DASHBOARD,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <Dashboard />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: EVENT_INFO,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <EventPage />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: GUEST_LIST,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <GuestListPage />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: GUEST_BULK_PREVIEW,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <GuestBulkUploadPreview />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: GUEST_ADD,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <GuestAdd />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: GUEST_EDIT,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <GuestEdit />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: MESSAGE_BLAST,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <MessageBlast />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: MESSAGE_BLAST_ADD,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <MessageBlastAdd />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: MESSAGE_BLAST_EDIT,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <MessageBlastEdit />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: CHECKIN,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <CheckInList />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: SEATING_CHART,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <SeatingChart />
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: REPORT_OVERVIEW,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <ReportOverview />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: SEATS_MANAGEMENT,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <SeatManagementList />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: SEATS_MANAGEMENT_EDIT,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <SeatManagementEdit />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: DIETARY_MANAGEMENT,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <DietaryList />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: DIETARY_EDIT,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <DietaryEdit />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: GUEST_LIST_IMPORT_LOG,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <ImportLogList />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: GUEST_LIST_IMPORT_LOG_DETAILS,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <MainLayout>
            <ImportLogDetails />
          </MainLayout>
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
    {
      path: EVENT_CHECK_IN_PAGE,
      element: isAuthenticated() ? (
        hasEventSelected() ? (
          <EventCheckInPage />
        ) : (
          <Navigate to={EVENT_LIST} />
        )
      ) : (
        <Navigate to={LOGIN} />
      ),
    },
  ];

  return useRoutes(routes);
}
