import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import SearchableDropdown from "./SearchableDropdown";
import DropdownSet from "./DropdownSet";
import axios from "axios";
import { SEND_EMAIL_API } from "../Constant/api";
import { useToast } from "./Toast";

const MessageBlastPopUp = ({
  togglePopUp,
  guestCount,
  guestOptions,
  templateId,
  setLoading,
}) => {
  const radioList = [
    {
      id: 1,
      value: "all_guests",
      label: "All guests on the guest list. " + `(${guestCount.all_guests})`,
      dropDown: false,
    },
    {
      id: 2,
      value: "not_received",
      label:
        "Guests who haven't receive this email. " +
        `(${guestCount.not_received})`,
      dropDown: false,
    },
    {
      id: 3,
      value: "attending",
      label: "Guests who attended this event. " + `(${guestCount.attending})`,
      dropDown: false,
    },
    {
      id: 4,
      value: "not_attending",
      label:
        "Guests who don't attend this event. " +
        `(${guestCount.not_attending})`,
      dropDown: false,
    },
    {
      id: 5,
      value: "individual",
      label: "Selected individual guest(s)",
      dropDown: true,
    },
  ];

  const [guestSelected, setGuestSelected] = useState([]);
  const [selectedValue, setSelectedValue] = useState(radioList[0].value);

  const { toast } = useToast();

  const handleSendEmail = () => {
    setLoading(true);
    try {
      axios
        .post(
          SEND_EMAIL_API,
          {
            eventId: localStorage.getItem("event_id"),
            templateId: templateId,
            type: selectedValue,
            individualEmail: guestSelected,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          togglePopUp();
          toast("Email sent successfully", "success");
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast("Failed to send email", "error");
    }
  };

  return (
    <Modal
      title="Select Recipients"
      description="Recipients don't include guest without an email address."
      toggleModal={togglePopUp}
      handleSubmit={handleSendEmail}
      content={
        <div className="flex flex-col w-full gap-[26px]">
          {radioList &&
            guestCount &&
            guestOptions &&
            radioList.map((item) => (
              <div className="flex flex-col w-full gap-[14px]">
                <div className="flex gap-[8px] items-center">
                  <input
                    name="type"
                    type="radio"
                    className="size-6 accent-[#5C4BF5] hover:cursor-pointer"
                    checked={selectedValue === item.value}
                    onChange={() => setSelectedValue(item.value)}
                  />
                  <label>{item.label}</label>
                </div>
                {item.dropDown && (
                  <div className="flex w-full justify-end">
                    <DropdownSet
                      value={guestSelected}
                      options={guestOptions}
                      isMulti
                      disabled={selectedValue !== "individual"}
                      label="Guests"
                      name="guests"
                      onChange={(name, value) => {
                        setGuestSelected(value);
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
        </div>
      }
    />
  );
};

export default MessageBlastPopUp;
