import { Add, Minus } from "iconsax-react";
import React from "react";

const Counter = ({ title, formData, setFormData, required }) => {
  const incrementSeats = () => {
    setFormData((prevData) => ({ ...prevData, pax: prevData.pax + 1 }));
  };

  const decrementSeats = () => {
    setFormData((prevData) =>
      prevData.pax > 1
        ? { ...prevData, pax: prevData.pax - 1 }
        : { ...prevData, pax: 1 }
    );
  };
  return (
    <div className="flex flex-col w-full gap-[5px]">
      <h2 className="text-[14px]">
        {title}
        {required && <span className="text-red-500">*</span>}
      </h2>
      <div className="flex w-full h-fit items-center border border-gray-300 rounded-lg justify-between">
        <button
          onClick={decrementSeats}
          className="h-full px-[9px] focus:outline-none"
        >
          <Minus size="20" />
        </button>
        <input
          id="seat-counter"
          type="number"
          value={formData.pax}
          onChange={(e) =>
            setFormData({ ...formData, pax: parseInt(e.target.value) })
          }
          className="w-full h-[42px] text-center text-[14px] border-none focus:outline-none py-[8px] px-[16px]"
        />
        <button
          onClick={incrementSeats}
          className="h-full px-[9px] focus:outline-none"
        >
          <Add size="20" />
        </button>
      </div>
    </div>
  );
};

export default Counter;
