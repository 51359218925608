import React, { useState, useEffect } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import ImportLogTable from "../../Components/Tables/ImportLogTable";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useToast } from "../../Components/Toast";
import { GET_IMPORT_LOG_DETAILS_API } from "../../Constant/api";

const ImportLogDetails = () => {
  const [loading, setLoading] = useState(false);
  const [importDetails, setImportDetails] = useState([]);
  const [importDetailsMeta, setImportDetailsMeta] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();
  const location = useLocation();

  const importId = location.state.importId;
  const fileName = location.state.fileName;

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchImportDetails(1, e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchImportDetails();
    }
  };

  const fetchImportDetails = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_IMPORT_LOG_DETAILS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            importId: importId,
            page: page,
            perPage: perPageValue,
            search: searchTerm,
          },
        })
        .then((response) => {
          setLoading(false);
          setImportDetails(response.data.data);
          setImportDetailsMeta(response.data.meta.pagination);
        })
        .catch((error) => {
          console.error("Error fetching `import details`:", error);
          setLoading(false);
          toast("Failed to fetch import details.", "error");
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch import details.", "error");
      console.error("Error fetching `import details`:", error);
    }
  };

  useEffect(() => {
    fetchImportDetails();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">{fileName}</h1>
            </div>

            {/* Guest list table */}
            <div className="w-full">
              <ImportLogTable
                type="details"
                data={importDetails}
                meta={importDetailsMeta}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default ImportLogDetails;
