import React, { useEffect, useState } from "react";
import Button from "../../Components/Button";
import EmailBlastForm from "../../Components/EmailBlastForm";
import { Send2 } from "iconsax-react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  GET_EMAIL_TEMPLATE_DETAILS_API,
  UPDATE_EMAIL_TEMPLATE_API,
} from "../../Constant/api";
import { useToast } from "../../Components/Toast";
import { MESSAGE_BLAST } from "../../Router/path";
import EmailGuide from "../../Components/EmailGuide";

const MessageBlastEdit = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [newFormData, setNewFormData] = useState({});
  const [resetKey, setResetKey] = useState(0); // Add a key state

  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const recordId = location.state.recordId;

  const handleChange = (e) => {
    setNewFormData({ ...newFormData, [e.target.name]: e.target.value });
  };

  const handleReset = () => {
    const resetFormData = JSON.parse(JSON.stringify(formData));
    setNewFormData(resetFormData);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleUpdate = () => {
    setLoading(true);
    try {
      axios
        .put(UPDATE_EMAIL_TEMPLATE_API, newFormData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
        })
        .then((response) => {
          setLoading(false);
          navigate(MESSAGE_BLAST, { state: { type: "Email Template" } });
          toast("Email template updated successfully", "success");
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to update email template", "error");
        });
    } catch (error) {
      console.error(error);
      toast("Failed to update email template", "error");
    }
  };

  const fetchEmailTemplateRecord = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_EMAIL_TEMPLATE_DETAILS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { templateId: recordId },
        })
        .then((response) => {
          const fetchedData = response.data.data;
          setFormData(fetchedData);
          setNewFormData({ ...fetchedData });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          toast("Failed to fetch email template record", "error");
        });
    } catch (error) {
      console.error(error);
      toast("Failed to fetch email template record", "error");
    }
  };

  useEffect(() => {
    fetchEmailTemplateRecord();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full">
            <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
              {/* Title row */}
              <div className="flex w-full justify-between items-center">
                <h1 className="text-[29px]">Edit</h1>
                {/* <div className="flex gap-[8px]">
                  <Button variant="secondary">Preview</Button>
                  <Button variant="primary">
                    <Send2 size="16" variant="Bold" />
                    Send Email
                  </Button>
                </div> */}
              </div>

              {/* Guide */}
              <EmailGuide />

              {/* Form */}
              {formData && newFormData && (
                <div className="w-full">
                  <EmailBlastForm
                    key={resetKey}
                    formData={newFormData}
                    handleChange={handleChange}
                  />
                </div>
              )}

              {/* Button */}
              <div className="flex w-full gap-[8px] items-center">
                <Button variant="primary" onClick={handleUpdate}>
                  Save Draft
                </Button>
                <Button variant="secondary" onClick={handleReset}>
                  Reset Template
                </Button>
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate(MESSAGE_BLAST, {
                      state: { type: "Email Template" },
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default MessageBlastEdit;
