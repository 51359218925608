import React, { useState, useEffect } from "react";
import Button from "../../Components/Button";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CHECKIN, SEATING_CHART } from "../../Router/path";
import { LoginCurve } from "iconsax-react";
import CheckInSlider from "../../Components/CheckIn/CheckInSlider";
import LoadingInComponent from "../../Components/LoadingInComponent";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import {
  DIETARY_OPTIONS_API,
  GET_EVENT_DETAILS_API,
  GET_GUEST_OPTIONS_API,
  GET_GUESTS_API,
  GET_OPEN_TABLES_API,
  WALK_IN_API,
} from "../../Constant/api";
import CheckInForm from "../../Components/CheckIn/CheckInForm";

const EventCheckInPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [guestId, setGuestId] = useState("");
  const [checkInOpen, setCheckInOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState([]);
  const [guestOptions, setGuestOptions] = useState([]);
  const [tableOptions, setTableOptions] = useState([]);
  const [dietaryOptions, setDietaryOptions] = useState([]);
  const [eventDetails, setEventDetails] = useState({});
  const [formData, setFormData] = useState({
    salutation: "",
    name: "",
    email: "",
    contact: "",
    special_dietary_id: "",
    group_name: "",
    isReplacement: false,
    table_id: "",
    guest_id: "",
    note: "",
  });

  const { toast } = useToast();
  const navigate = useNavigate();

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDropDownChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSwitchChange = (checked) => {
    setFormData({ ...formData, isReplacement: checked });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const validateCheckIn = ({ redirect } = {}) => {
    setLoading(true);
    if (formData.table_id === "" && !redirect && !formData.isReplacement) {
      setLoading(false);
      toast("Please select a table", "error");
    } else if (
      formData.table_id === "" &&
      redirect &&
      !formData.isReplacement
    ) {
      handleCheckIn({ redirect: true });
    } else {
      handleCheckIn({ redirect: false });
    }
  };

  const handleCheckIn = async ({ redirect } = {}) => {
    try {
      axios
        .post(WALK_IN_API, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then(() => {
          setFormData({
            salutation: "",
            name: "",
            email: "",
            contact: "",
            special_dietary_id: "",
            group_name: "",
            isReplacement: false,
            table_id: "",
            guest_id: "",
            note: "",
          });
          fetchGuestList();
          toggleModal();
          setLoading(false);
          if (redirect) {
            navigate(SEATING_CHART);
          }
          toast("Guest checked in successfully", "success");
        })
        .catch((error) => {
          toast("Failed to check in guest", "error");
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      toast("Failed to check in guest", "error");
      console.error(error);
      setLoading(false);
    }
  };

  const fetchGuestList = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_GUESTS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setGuestList(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch guest list", "error");
          console.error(error);
        });
    } catch (error) {
      toast("Failed to fetch guest list", "error");
      console.error(error);
    }
  };

  const fetchEventDetails = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_EVENT_DETAILS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setEventDetails(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch event details", "error");
          console.error(error);
        });
    } catch (error) {
      toast("Failed to fetch event details", "error");
      console.error(error);
    }
  };

  const fetchTableOptions = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_OPEN_TABLES_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setTableOptions(response.data.data);
          setLoading(false);
        });
    } catch (error) {
      toast("Failed to fetch table list", "error");
      console.error(error);
    }
  };

  const fetchDietaryOptions = async () => {
    setLoading(true);
    try {
      axios
        .get(DIETARY_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setDietaryOptions(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch dietary list", "error");
          console.error(error);
        });
    } catch (error) {
      toast("Failed to fetch dietary list", "error");
      console.error(error);
    }
  };

  const fetchGuestOptions = async () => {
    setLoading(true);
    try {
      axios
        .get(GET_GUEST_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((response) => {
          setGuestOptions(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          toast("Failed to fetch guest options", "error");
          console.error(error);
          setLoading(false);
        });
    } catch (error) {
      toast("Failed to fetch guest options", "error");
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const guestId = params.get("guest_id");
    if (guestId) {
      setGuestId(guestId);
      setCheckInOpen(true);
    }
  }, []);

  useEffect(() => {
    fetchGuestList();
    fetchGuestOptions();
    fetchEventDetails();
    fetchTableOptions();
    fetchDietaryOptions();
  }, []);

  return (
    <LoadingInComponent isLoading={loading}>
      {/* Add Walk-In Guest Modal */}
      {showModal && (
        <CheckInForm
          formData={formData}
          toggleModal={toggleModal}
          handleCheckIn={validateCheckIn}
          handleFormInputChange={handleFormInputChange}
          handleDropDownChange={handleDropDownChange}
          handleSwitchChange={handleSwitchChange}
          dietaryOption={dietaryOptions}
          tableOption={tableOptions}
          guestOption={guestOptions}
        />
      )}
      <div className="flex flex-col lg:flex-row min-h-screen">
        {/* Left side content */}
        <div
          className="w-full h-[60vh] lg:h-screen lg:w-3/5 bg-cover bg-center"
          style={{
            backgroundImage: eventDetails.image
              ? `url(${eventDetails.image})`
              : "url(https://placehold.co/890x1024?text=No+Image)",
          }}
        >
          {/* Exit button */}
          <Button
            variant="secondary"
            onClick={() => navigate(CHECKIN)}
            className="absolute top-4 left-4 shadow-lg"
          >
            <XMarkIcon className="size-[16px]" />
            Exit Page
          </Button>
        </div>

        {/* Right side content */}
        <div
          className="flex flex-col w-full h-[40vh] lg:w-2/5 lg:h-screen items-center justify-center bg-cover bg-center gap-[48px]"
          // style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="flex flex-col text-center text-[#514539] gap-[32px]">
            <h2 className="text-lg font-light">- Welcome to -</h2>
            <h1 className="text-4xl font-semibold">{eventDetails.name}</h1>
            <h3 className="text-lg font-light">{eventDetails.date}</h3>
            <p>{eventDetails.venue}</p>
          </div>

          <div className="flex flex-col items-center gap-4">
            <div className="grid grid-cols-2 gap-4">
              <Button variant="secondary" onClick={toggleModal}>
                Add Walk-In Guest
              </Button>

              {/* <Button variant="fourth" onClick={handleScanQR}>
              <QrCodeIcon className="size-[18px]" />
              Scan Qr Code
            </Button> */}
              <Button
                variant="fourth"
                onClick={() => setCheckInOpen(!checkInOpen)}
              >
                <LoginCurve size="16" />
                Check In
              </Button>
            </div>
          </div>
        </div>
        {checkInOpen && (
          <CheckInSlider
            guestList={guestList}
            guestListRefresh={fetchGuestList}
            setIsOpen={setCheckInOpen}
            guestId={guestId}
          />
        )}
      </div>
    </LoadingInComponent>
  );
};

export default EventCheckInPage;
