import React from "react";
import Button from "./Button";
import { XMarkIcon } from "@heroicons/react/24/solid";

const Modal = ({
  title,
  description,
  toggleModal,
  confirmation,
  handleSubmit,
  submitLabel,
  content,
}) => {
  return (
    <div className="fixed flex left-0 top-0 z-20 w-full h-screen bg-black bg-opacity-50 backdrop-blur-md items-center justify-center">
      <div className="flex flex-col w-full max-w-[600px] p-[24px] rounded-xl bg-white">
        <div className="flex w-full justify-between">
          <div>
            <h2 className="w-full text-[17px]">{title}</h2>
            <p className="text-[14px] font-normal text-[#636C7E]">
              {description}
            </p>
          </div>
          <XMarkIcon
            className="size-[24px] cursor-pointer"
            onClick={toggleModal}
          />
        </div>

        <div className="flex py-[24px]">{content}</div>
        <div className="flex gap-[8px]">
          {handleSubmit && (
            <Button variant="primary" onClick={handleSubmit}>
              {submitLabel ?? "Create"}
            </Button>
          )}
          {confirmation && (
            <Button
              variant="primary"
              onClick={() => {
                confirmation();
                toggleModal();
              }}
            >
              {submitLabel ?? "Confirm"}
            </Button>
          )}
          <Button variant="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
