import React, { useState } from "react";
import Button from "../Button";
import FilterTextInput from "../FilterTextInput";
import ReactPaginate from "react-paginate";
import {
  ArrowLeft2,
  ArrowRight2,
  Edit2,
  MinusCirlce,
  TickCircle,
} from "iconsax-react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { GET_TABLE_GUESTS_API } from "../../Constant/api";
import Modal from "../Modal";
import { useToast } from "../Toast";
import { SEATING_CHART, SEATS_MANAGEMENT_EDIT } from "../../Router/path";
import PerPageSelector from "../PerPageSelector";

const SeatManagementTable = ({
  data,
  meta,
  handlePageClick,
  searchTerm,
  handleSearch,
  handlePerPageChange,
  perPage,
}) => {
  const currentPage = meta ? meta.page - 1 : 0;

  const [tableGuests, setTableGuests] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const { toast } = useToast();
  const navigate = useNavigate();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleViewGuests = (tableId) => {
    try {
      axios
        .get(GET_TABLE_GUESTS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            tableId: tableId,
            eventId: localStorage.getItem("event_id"),
          },
        })
        .then((response) => {
          setTableGuests(response.data.data);
          setShowModal(true);
        })
        .catch((error) => {
          toast("Failed to fetch table guests", "error");
          console.log(error);
        });
    } catch (error) {
      toast("Failed to fetch table guests", "error");
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {showModal && (
        <Modal
          title="Guest List"
          toggleModal={toggleModal}
          content={
            <div className="flex flex-col w-full border rounded-xl">
              {tableGuests && tableGuests.length > 0 ? (
                <>
                  <div className="flex w-full border-b p-[16px] items-center">
                    <h1 className="flex w-full justify-start">Guest Name</h1>
                    <h1 className="flex w-full justify-end">Check In</h1>
                  </div>
                  {tableGuests.map((item, index) => (
                    <div
                      key={index}
                      className="flex w-full p-[16px] items-center border-b last:border-none"
                    >
                      <p className="flex w-full justify-start font-normal">
                        {index + 1}. {item.guest_name}
                      </p>
                      <p className="flex w-full justify-end font-normal">
                        {item.guest_status && (
                          <TickCircle
                            size="24"
                            color="#37D67A"
                            variant="Bold"
                          />
                        )}
                      </p>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col justify-center items-center p-[36px]">
                  <h1 className="text-[20px] text-gray-500 ">
                    No guests assigned to this table
                  </h1>
                </div>
              )}
            </div>
          }
        />
      )}
      {/* Title */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[16px] justify-between items-center">
        <h2>Seats Management</h2>
        <Button onClick={() => navigate(SEATING_CHART)}>Seatings Chart</Button>
      </div>

      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput value={searchTerm} onChange={handleSearch} />
      </div>

      {/* Table data */}
      <div className="flex w-full h-full gap-[24px] no-scrollbar overflow-x-auto">
        {!data || data.length === 0 ? (
          <div className="flex w-full h-[290px] items-center border-[#EFF0F2] justify-center text-gray-500 text-[25px]">
            No table found
          </div>
        ) : (
          <table className="w-full h-full">
            {/* Table Header */}
            <tr className="w-full bg-[#F7F8F9] border-b border-[#EFF0F2]">
              {/* <th className="text-start px-[24px] py-[16px]">
                <input type="checkbox" />
              </th> */}
              <th className="text-start px-[24px] py-[16px]">Table Name</th>
              <th className="text-start px-[24px] py-[16px]">Assigned/Seats</th>
              <th className="text-start px-[24px] py-[16px]">Checked-In</th>
              <th className="text-start px-[24px] py-[16px]">Guests</th>
              <th className="text-start px-[24px] py-[16px]">
                Table Description
              </th>
              <th className="text-start px-[24px] py-[16px]"></th>
            </tr>

            {/* Table Body */}
            {data &&
              data.map((item) => (
                <tr
                  key={item.id}
                  className="w-full font-normal border-b last:border-none border-[#EFF0F2]"
                >
                  {/* <td className="text-start px-[24px] py-[16px]">
                    <input type="checkbox" />
                  </td> */}
                  <td className="px-[24px] py-[16px]">{item.name ?? "-"}</td>
                  <td className="px-[24px] py-[16px]">
                    <div className="flex flex-col w-full">
                      <p className="text-[12px]">
                        {item.guest_count} of {item.pax}
                      </p>
                      <div className="relative w-full min-h-[4px] max-h-[4px] bg-[#E0E2E5] rounded-2xl">
                        <div
                          className="absolute left-0 top-0 min-h-[4px] max-h-[4px] bg-[#5C4BF5] rounded-2xl"
                          style={{ width: `${item.percentage}%` }}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className="px-[24px] py-[16px]">
                    {item.guest_count_confirmed}
                  </td>
                  <td className="px-[24px] py-[16px]">
                    <Button
                      variant=""
                      className="hover:text-[#5C4BF5]"
                      onClick={() => handleViewGuests(item.id)}
                    >
                      View List
                    </Button>
                  </td>
                  <td className="px-[24px] py-[16px] max-w-[200px] truncate">
                    {item.description ?? "-"}
                  </td>
                  <td className="flex px-[24px] py-[16px] h-full gap-[12px] items-center justify-end">
                    <span
                      className="flex text-[#5C4BF5] text-[14px] items-center hover:underline cursor-pointer"
                      onClick={() =>
                        navigate(SEATS_MANAGEMENT_EDIT, {
                          state: { tableId: item.id },
                        })
                      }
                    >
                      <Edit2 size="15" variant="Bold" />
                      Edit
                    </span>
                  </td>
                </tr>
              ))}
          </table>
        )}
      </div>

      {/* Pagination */}
      {meta && (
        <div className="grid grid-cols-3 w-full px-[24px] py-[12px] border-t border-[#EFF0F2] items-center justify-between font-normal">
          {/* Page number and total */}
          <div className="grid w-full">
            Showing page {meta.page} of {meta.total_pages}, Total {meta.total}
          </div>

          {/* Number per page selector */}
          <div className="grid w-full justify-center">
            <PerPageSelector
              perPage={perPage}
              handlePerPageChange={handlePerPageChange}
            />
          </div>

          {/* Page selector */}
          <div className="grid w-full justify-end">
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={4}
              marginPagesDisplayed={1}
              pageCount={meta.total_pages}
              forcePage={currentPage}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SeatManagementTable;
