import React, { useState, useEffect } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import ImportLogTable from "../../Components/Tables/ImportLogTable";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import { GET_IMPORT_LOG_LIST_API } from "../../Constant/api";

const ImportLogList = () => {
  const [loading, setLoading] = useState(false);
  const [importsData, setImportsData] = useState([]);
  const [importsMeta, setImportsMeta] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [perPage, setPerPage] = useState(10);

  const { toast } = useToast();

  const handlePerPageChange = (e) => {
    setPerPage(e.target.value);
    fetchImportsData(1, e.target.value);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.key === "Enter") {
      fetchImportsData();
    }
  };

  const fetchImportsData = async (page = 1, perPageValue = perPage) => {
    setLoading(true);
    try {
      axios
        .get(GET_IMPORT_LOG_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            page: page,
            eventId: localStorage.getItem("event_id"),
            perPage: perPage,
            search: searchTerm,
          },
        })
        .then((response) => {
          setLoading(false);
          setImportsData(response.data.data);
          setImportsMeta(response.data.meta.pagination);
        })
        .catch((error) => {
          console.error("Error fetching `imports`:", error);
          setLoading(false);
          toast("Failed to fetch import logs.", "error");
        });
    } catch (error) {
      setLoading(false);
      toast("Failed to fetch import logs.", "error");
      console.error("Error fetching `imports`:", error);
    }
  };

  useEffect(() => {
    fetchImportsData();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h2 className="text-[29px]">Guest List Import Log</h2>
            </div>

            {/* Guest list table */}
            <div className="w-full">
              <ImportLogTable
                type="list"
                data={importsData}
                meta={importsMeta}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
              />
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default ImportLogList;
