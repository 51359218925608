import React, { useState } from "react";
import TextboxSet from "../Components/TextboxSet";
import Button from "../Components/Button";
import axios from "axios";
import { LOGIN_API } from "../Constant/api";
import { useToast } from "../Components/Toast.jsx";
import { useNavigate } from "react-router-dom";
import { EVENT_LIST } from "../Router/path";
import { LoadingOverlay } from "../Components/LoadingOverlay.jsx";

const Login = () => {
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (loginForm.email && loginForm.password) {
      setLoading(true);
      axios({
        method: "post",
        url: LOGIN_API,
        data: {
          email: loginForm.email,
          password: loginForm.password,
        },
      })
        .then((response) => {
          setLoading(false);
          if (response.data.success) {
            localStorage.setItem(
              "bearer_token",
              response.data.data.bearer_token
            );
            navigate(EVENT_LIST);
            toast("Login Success", "success");
          } else {
            toast(response.data.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && error.response.data.data.email) {
            toast(error.response.data.data.email, "error");
          } else {
            toast(error.response.data.message, "error");
          }
        });
    } else {
      toast("Please fill all the fields", "error");
    }
  };

  return (
    <div className="flex flex-col w-full h-screen items-center justify-center">
      {loading && <LoadingOverlay />}
      <div className="flex flex-col w-[400px] h-[500px] bg-white rounded-lg p-[32px] items-center justify-center gap-[32px] shadow-lg">
        <div>
          <img src="images/logo.png" className="h-[40px]" alt="logo" />
        </div>
        <form
          className="flex flex-col w-full gap-[16px]"
          onSubmit={handleLogin}
        >
          <TextboxSet
            label="Email"
            name="email"
            value={loginForm.email}
            placeholder="Email"
            type="email"
            className="w-full"
            onChange={handleChange}
          />
          <TextboxSet
            label="Password"
            name="password"
            value={loginForm.password}
            placeholder="Password"
            type="password"
            className="w-full"
            onChange={handleChange}
          />
          <div className="w-full flex mt-[16px]">
            <Button
              variant="primary"
              type="submit"
              className="w-full justify-center"
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
