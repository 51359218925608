import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import FilterTextInput from "../../Components/FilterTextInput";
import TemplateCards from "../../Components/TemplateCards";

const PageTemplate = ({
  handlePageClick,
  templateList,
  templateListMeta,
  eventInfo,
  setEventInfo,
}) => {
  return (
    <div className="flex flex-col border border-[#EFF0F2] w-full h-fit bg-white rounded-xl">
      {/* Filters */}
      <div className="flex w-full border-b border-[#EFF0F2] px-[24px] py-[12px] justify-end">
        <FilterTextInput />
      </div>

      {/* Template data */}
      <div className="flex flex-wrap w-full min-h-[290px] px-[24px] py-[12px] gap-[24px] items-center justify-center">
        {templateList.length === 0 ? (
          <div className="flex w-full items-center justify-center text-gray-500 text-[25px]">
            No templates available
          </div>
        ) : (
          templateList.map((template) => (
            <TemplateCards
              key={template.id}
              id={template.id}
              imageSource={template.image}
              templateName={template.name}
              onChange={() => {
                setEventInfo({ ...eventInfo, template_id: template.id });
              }}
              checked={template.id === eventInfo.template_id}
            />
          ))
        )}
      </div>

      {/* Pagination */}
      {templateListMeta && templateListMeta.total > 0 && (
        <div className="flex w-full border-t border-[#EFF0F2] px-[24px] py-[12px] items-center justify-between font-normal">
          {/* Page number and total */}
          <div className="flex w-full">
            Showing page {templateListMeta.page} of{" "}
            {templateListMeta.total_pages}, Total {templateListMeta.total}
          </div>

          {/* Number per page selector */}
          <div className="flex w-full"></div>

          {/* Page selector */}
          <div className="flex w-full justify-end">
            <ReactPaginate
              onPageChange={(e) => handlePageClick(e)}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={templateListMeta.total_pages}
              forcePage={templateListMeta.page - 1}
              breakLabel="..."
              previousLabel={<ArrowLeft2 size="16px" />}
              nextLabel={<ArrowRight2 size="16px" />}
              nextClassName="paginator-page-btn"
              previousClassName="paginator-page-btn"
              containerClassName="flex rounded-xl border font-medium"
              activeClassName="paginator-page-btn bg-[#F7F8F9] text-[#5C4BF5]"
              pageClassName="paginator-page-btn border-x"
              breakClassName="paginator-page-btn border-x"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageTemplate;
