import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  ArrangeVertical,
  Clock,
  ImportCurve,
  ImportSquare,
  InfoCircle,
  TickCircle,
  Trash,
} from "iconsax-react";
import React, { useEffect, useState } from "react";
import LoadingInComponent from "../../Components/LoadingInComponent";
import Button from "../../Components/Button";
import SectionCard from "../../Components/SectionCard";
import TextboxSet from "../../Components/TextboxSet";
import DropdownSet from "../../Components/DropdownSet";
import { useToast } from "../../Components/Toast";
import axios from "axios";
import {
  DIETARY_OPTIONS_API,
  GUEST_CREATE_API,
  TABLE_OPTIONS_API,
} from "../../Constant/api";
import { useNavigate } from "react-router-dom";
import { GUEST_LIST } from "../../Router/path";

const GuestAdd = () => {
  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [tableList, setTableList] = useState([]);
  const [dietaryList, setDietaryList] = useState([]);
  const [guestInfoList, setGuestInfoList] = useState([
    {
      id: "guest-0",
      salutation: "",
      name: "",
      email: "",
      contact: "",
      table: "",
      dietary: "",
      note: "",
      status: false,
    },
  ]);

  const { toast } = useToast();
  const navigate = useNavigate();

  const fetchDietaryList = () => {
    try {
      setLoading(true);
      axios
        .get(DIETARY_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((res) => {
          setLoading(false);
          setDietaryList(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast("Failed to fetch dietary list.", "error");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast("Failed to fetch dietary list.", "error");
    }
  };

  const fetchTableList = () => {
    setLoading(true);
    try {
      axios
        .get(TABLE_OPTIONS_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { eventId: localStorage.getItem("event_id") },
        })
        .then((res) => {
          setLoading(false);
          setTableList(res.data.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast("Failed to fetch table list.", "error");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast("Failed to fetch table list.", "error");
    }
  };

  const handleCreate = () => {
    setLoading(true);
    try {
      axios
        .post(
          GUEST_CREATE_API,
          {
            guests: guestInfoList,
            group_name: groupName,
            eventId: localStorage.getItem("event_id"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          navigate(GUEST_LIST);
          toast("Guest added successfully.", "success");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          toast(err.response.data.message, "error");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast(error.response.data.message, "error");
    }
  };

  const handleOnChange = (event, index) => {
    const { name, value } = event.target;
    setGuestInfoList((prev) => {
      let newGuestInfoList = [...prev];
      newGuestInfoList[index][name] = value;
      return newGuestInfoList;
    });
  };

  const handleDropDownChange = (index, name, value) => {
    setGuestInfoList((prev) => {
      let newGuestInfoList = [...prev];
      newGuestInfoList[index][name] = value;
      return newGuestInfoList;
    });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedForms = [...guestInfoList];
    const [movedForm] = reorderedForms.splice(result.source.index, 1);
    reorderedForms.splice(result.destination.index, 0, movedForm);

    setGuestInfoList(reorderedForms);
  };

  const handleDelete = (id) => {
    setGuestInfoList(guestInfoList.filter((guest) => guest.id !== id));
  };

  useEffect(() => {
    fetchTableList();
    fetchDietaryList();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <LoadingInComponent isLoading={loading}>
        {!loading && (
          <div className="flex flex-col w-full h-full p-[32px] no-scrollbar overflow-y-auto gap-[24px]">
            {/* Title row */}
            <div className="flex w-full justify-between items-center">
              <h1 className="text-[29px]">Add Guest</h1>
              <div className="flex w-fit items-center gap-[8px]">
                <Button variant="tertiary">
                  <ImportCurve size="16" variant="Outline" /> Download Excel
                  Template
                </Button>
                <Button variant="secondary">
                  <ImportSquare size={16} variant="Outline" /> Import Guest List
                </Button>
              </div>
            </div>

            {/* Guest Name card */}
            <div className="flex flex-col w-full h-fit p-[24px] border border-[#EFF0F2] bg-white rounded-xl gap-[8px]">
              <h2 className="text-[14px]">Group Name</h2>
              <div className="w-full px-[12px] py-[11px] border border-[#EFF0F2] rounded-xl">
                <input
                  type="text"
                  value={groupName}
                  className="w-full focus:outline-none"
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                />
              </div>
              <p className="flex text-[#A1A7B2] text-[11px] gap-[4px] items-center mx-[12px]">
                <InfoCircle size="11" variant="Bold" />
                Leave this field empty if you only want to add individual guest
              </p>
            </div>

            {/* Guest info */}
            <SectionCard
              title="Guest Information"
              content={
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="guests">
                    {(provided) => (
                      <div
                        className="flex flex-col w-full h-fit gap-[24px] justify-center items-center"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {guestInfoList.map((guest, index) => (
                          <Draggable
                            key={guest.id}
                            draggableId={guest.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="flex flex-col w-full h-fit border border-[#EFF0F2] bg-white rounded-xl"
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                {/* Sort and trash */}
                                <div className="flex w-full py-[16px] px-[24px] items-center justify-between border-b border-[#EFF0F2]">
                                  <ArrangeVertical size="24" color="#A1A7B2" />
                                  <Trash
                                    size="24"
                                    color="#FF0000"
                                    variant="Bold"
                                    onClick={() => handleDelete(guest.id)}
                                  />
                                </div>

                                {/* Details */}
                                <div className="flex flex-col w-full py-[16px] px-[24px] gap-[24px] items-center justify-between">
                                  <div className="flex w-full gap-[24px]">
                                    <TextboxSet
                                      type="text"
                                      name="salutation"
                                      value={guest.salutation}
                                      label="Title/Salutation"
                                      className="w-full"
                                      onChange={(e) => handleOnChange(e, index)}
                                    />
                                    <TextboxSet
                                      type="text"
                                      name="name"
                                      value={guest.name}
                                      label="Full Name"
                                      required
                                      className="w-full"
                                      onChange={(e) => handleOnChange(e, index)}
                                    />
                                    <TextboxSet
                                      type="email"
                                      name="email"
                                      value={guest.email}
                                      label="Email"
                                      className="w-full"
                                      onChange={(e) => handleOnChange(e, index)}
                                    />
                                  </div>
                                  <div className="flex w-full gap-[24px]">
                                    <TextboxSet
                                      type="number"
                                      name="contact"
                                      value={guest.contact}
                                      label="Contact Number"
                                      className="w-full"
                                      onChange={(e) => handleOnChange(e, index)}
                                    />
                                    <DropdownSet
                                      value={guest.table}
                                      options={tableList}
                                      label="Table"
                                      name="table"
                                      // onChange={(e) => handleOnChange(e, index)}
                                      onChange={(name, value) =>
                                        handleDropDownChange(index, name, value)
                                      }
                                    />
                                    <DropdownSet
                                      value={guest.dietary}
                                      options={dietaryList}
                                      label="Special Dietary"
                                      name="dietary"
                                      // onChange={(e) => handleOnChange(e, index)}
                                      onChange={(name, value) =>
                                        handleDropDownChange(index, name, value)
                                      }
                                    />
                                  </div>
                                  <div className="w-full">
                                    <TextboxSet
                                      type="textarea"
                                      name="note"
                                      value={guest.note}
                                      label="Note"
                                      className="w-full"
                                      onChange={(e) => handleOnChange(e, index)}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full gap-[8px]">
                                    <h2>
                                      Check-In Status
                                      <span className="text-red-500">*</span>
                                    </h2>
                                    <div className="flex gap-[16px]">
                                      <Button
                                        className="flex items-center gap-[8px]"
                                        variant={
                                          guest.status === false
                                            ? "primary"
                                            : "secondary"
                                        }
                                        onClick={() => {
                                          setGuestInfoList((prev) => {
                                            let newGuestInfoList = [...prev];
                                            newGuestInfoList[
                                              index
                                            ].status = false;
                                            return newGuestInfoList;
                                          });
                                        }}
                                      >
                                        <Clock size="16" variant="Bold" />
                                        Pending
                                      </Button>
                                      <Button
                                        className="flex items-center gap-[8px]"
                                        variant={
                                          guest.status === true
                                            ? "primary"
                                            : "secondary"
                                        }
                                        onClick={() => {
                                          setGuestInfoList((prev) => {
                                            let newGuestInfoList = [...prev];
                                            newGuestInfoList[
                                              index
                                            ].status = true;
                                            return newGuestInfoList;
                                          });
                                        }}
                                      >
                                        <TickCircle size="16" variant="Bold" />
                                        Checked
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div className="flex items-center justify-center mt-[24px]">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setGuestInfoList((prev) => {
                          let newGuestInfoList = [...prev];
                          newGuestInfoList.push({
                            id: `guest-${newGuestInfoList.length}`,
                            salutation: "",
                            name: "",
                            email: "",
                            contact: "",
                            table: "",
                            dietary: "",
                            status: false,
                          });
                          return newGuestInfoList;
                        });
                      }}
                    >
                      Add a guest
                    </Button>
                  </div>
                </DragDropContext>
              }
            />
            <div className="flex w-full gap-[8px]">
              <Button onClick={handleCreate}>Create</Button>
              <Button variant="secondary" onClick={() => navigate(GUEST_LIST)}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </LoadingInComponent>
    </div>
  );
};

export default GuestAdd;
