import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../Router/path";
import Header from "../Components/Header";
import Modal from "../Components/Modal";
import Switch from "react-switch";
import TextboxSet from "../Components/TextboxSet";
import axios from "axios";
import {
  CURRENT_EVENT_LIST_API,
  PAST_EVENT_LIST_API,
  EVENT_CREATE_API,
  GET_EVENT_INFO_API,
} from "../Constant/api";
import { useToast } from "../Components/Toast";
import { LoadingOverlay } from "../Components/LoadingOverlay";
import EventListTable from "../Components/Tables/EventListTable";
import LocationSet from "../Components/LocationSet";

const EventList = () => {
  const [activeTab, setActiveTab] = useState("current");
  const [currentPerPage, setCurrentPerPage] = useState(10);
  const [currentSearchTerm, setCurrentSearchTerm] = useState("");
  const [pastPerPage, setPastPerPage] = useState(10);
  const [pastSearchTerm, setPastSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentEventsMeta, setCurrentEventsMeta] = useState({});
  const [pastEvents, setPastEvents] = useState([]);
  const [pastEventsMeta, setPastEventsMeta] = useState({});
  const [modalFormData, setModalFormData] = useState({
    name: "",
    venue: "",
    date: "",
    time: "",
    description: "",
    seat_management: false,
    dietary_management: false,
  });

  const { toast } = useToast();

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setModalFormData({ ...modalFormData, [e.target.name]: e.target.value });
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handlePageClick = (e) => {
    if (activeTab === "current") {
      getCurrentEvents(e.selected + 1);
    } else if (activeTab === "past") {
      getPastEvents(e.selected + 1);
    }
  };

  const handleCurrentPerPageChange = (e) => {
    setCurrentPerPage(e.target.value);
    getCurrentEvents(1, e.target.value);
  };

  const handlePastPerPageChange = (e) => {
    setPastPerPage(e.target.value);
    getPastEvents(1, e.target.value);
  };

  const handleCurrentSearch = (e) => {
    setCurrentSearchTerm(e.target.value);
    if (e.key === "Enter") {
      getCurrentEvents();
    }
  };

  const handlePastSearch = (e) => {
    setPastSearchTerm(e.target.value);
    if (e.key === "Enter") {
      getPastEvents();
    }
  };

  const handleSubmit = () => {
    if (
      modalFormData.name !== "" &&
      modalFormData.venue !== "" &&
      modalFormData.date !== "" &&
      modalFormData.time !== ""
    ) {
      if (new Date(modalFormData.date) > Date.now()) {
        try {
          axios
            .post(EVENT_CREATE_API, modalFormData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
              },
            })
            .then((response) => {
              toggleModal();
              getCurrentEvents();
              getPastEvents();
              toast("Event created successfully", "success");
            })
            .catch((error) => {
              toast("Failed to create event", "error");
              console.log(error);
            });
        } catch (error) {
          toast("Failed to create event", "error");
          console.log(error);
        }
      } else {
        toast("Date has to be after today.", "error");
      }
    } else {
      toast("Please fill in all the required fields", "error");
    }
  };

  const getCurrentEvents = async (page = 1, perPageValue = currentPerPage) => {
    setLoading(true);
    try {
      axios
        .get(CURRENT_EVENT_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: {
            page: page,
            perPage: perPageValue,
            search: currentSearchTerm,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setCurrentEvents(response.data.data);
            setCurrentEventsMeta(response.data.meta.pagination);
            setLoading(false);
          } else {
            toast(response.data.message, "error");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Failed to fetch events", "error");
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      toast("Something went wrong", "error");
      setLoading(false);
    }
  };

  const getPastEvents = async (page = 1, perPageValue = pastPerPage) => {
    setLoading(true);
    try {
      axios
        .get(PAST_EVENT_LIST_API, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
          },
          params: { page: page, perPage: perPageValue, search: pastSearchTerm },
        })
        .then((response) => {
          if (response.data.success) {
            setPastEvents(response.data.data);
            setPastEventsMeta(response.data.meta.pagination);
            setLoading(false);
          } else {
            toast(response.data.message, "error");
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Failed to fetch events", "error");
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      toast("Something went wrong", "error");
      setLoading(false);
    }
  };

  const handleRecordClick = (event_id) => {
    if (event_id) {
      setLoading(true);
      try {
        axios
          .get(GET_EVENT_INFO_API, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("bearer_token")}`,
            },
            params: { eventId: event_id },
          })
          .then((response) => {
            if (response.data.success) {
              localStorage.setItem("event_id", event_id);
              localStorage.setItem(
                "dietary",
                response.data.data.dietary_management
              );
              localStorage.setItem("seat", response.data.data.seat_management);
              navigate(DASHBOARD);
            } else {
              toast(response.data.message, "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            toast("Failed to fetch event info", "error");
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast("Something went wrong", "error");
      }
    }
  };

  useEffect(() => {
    getCurrentEvents();
    getPastEvents();
  }, []);

  return (
    <div>
      {loading && <LoadingOverlay />}
      {/* Create event modal */}
      {showModal && (
        <Modal
          title="Create Event"
          toggleModal={toggleModal}
          handleSubmit={handleSubmit}
          content={
            <div className="flex flex-col w-full h-full gap-[24px]">
              <div className="flex flex-col md:flex-row w-full gap-[24px]">
                <TextboxSet
                  onChange={handleOnChange}
                  type="text"
                  label="Event Title"
                  placeholder="Event Title"
                  name="name"
                  required
                />
                <LocationSet
                  onChange={handleOnChange}
                  name="venue"
                  placeholder="Venue"
                  required
                />
              </div>
              <div className="flex flex-col md:flex-row w-full gap-[24px]">
                <TextboxSet
                  onChange={handleOnChange}
                  type="date"
                  label="Date"
                  name="date"
                  required
                />
                <TextboxSet
                  onChange={handleOnChange}
                  type="time"
                  label="Time"
                  name="time"
                  required
                />
              </div>
              <div className="flex w-full gap-[24px]">
                <TextboxSet
                  onChange={handleOnChange}
                  type="textarea"
                  label="Event Description"
                  name="description"
                />
              </div>
              <div className="flex flex-col gap-[24px]">
                <div className="flex w-full gap-[16px] items-center">
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offColor="#EFF0F2"
                    onColor="#5C4BF5"
                    width={48}
                    height={28}
                    checked={modalFormData.seat_management}
                    onChange={() => {
                      setModalFormData({
                        ...modalFormData,
                        seat_management: !modalFormData.seat_management,
                      });
                    }}
                  />
                  <p>Seats Management</p>
                </div>
                <div className="flex w-full gap-[16px] items-center">
                  <Switch
                    checkedIcon={false}
                    uncheckedIcon={false}
                    offColor="#EFF0F2"
                    onColor="#5C4BF5"
                    width={48}
                    height={28}
                    checked={modalFormData.dietary_management}
                    onChange={() => {
                      setModalFormData({
                        ...modalFormData,
                        dietary_management: !modalFormData.dietary_management,
                      });
                    }}
                  />
                  <p>Special Dietray Management</p>
                </div>
              </div>
            </div>
          }
        />
      )}
      <Header noSidebar={true} />
      <div className="flex flex-col w-full h-[100%] py-[32px] px-[32px] lg:px-[128px] items-center gap-[32px] bg-[#F7F8F9]">
        {/* Title section */}
        <div className="flex w-full justify-between">
          <h1 className="text-[29px]">Your Event</h1>
          <Button variant="primary" onClick={() => setShowModal(!showModal)}>
            Create Event
          </Button>
        </div>

        {/* Tab section */}
        <div className="flex w-fit p-[12px] border border-[#EFF0F2] bg-white rounded-xl gap-[8px]">
          <Button
            variant={
              activeTab === "current"
                ? "tab_button_selected"
                : "tab_button_default"
            }
            onClick={() => setActiveTab("current")}
            tabNumber={currentEventsMeta.total}
          >
            Current Events
          </Button>
          <Button
            variant={
              activeTab === "past"
                ? "tab_button_selected"
                : "tab_button_default"
            }
            tabNumber={pastEventsMeta.total}
            onClick={() => setActiveTab("past")}
          >
            Past Events
          </Button>
        </div>

        {/* Event list section */}
        {activeTab === "current" && (
          <EventListTable
            eventList={currentEvents}
            eventMeta={currentEventsMeta}
            handleRecordClick={handleRecordClick}
            handlePageClick={handlePageClick}
            perPage={currentPerPage}
            handlePerPageChange={handleCurrentPerPageChange}
            searchTerm={currentSearchTerm}
            handleSearch={handleCurrentSearch}
          />
        )}
        {activeTab === "past" && (
          <EventListTable
            eventList={pastEvents}
            eventMeta={pastEventsMeta}
            handleRecordClick={handleRecordClick}
            handlePageClick={handlePageClick}
            perPage={pastPerPage}
            handlePerPageChange={handlePastPerPageChange}
            searchTerm={pastSearchTerm}
            handleSearch={handlePastSearch}
          />
        )}
      </div>
    </div>
  );
};

export default EventList;
