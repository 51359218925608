// Prefixes for API calls
const API_BASE_PREFIX = process.env.REACT_APP_API_BASE_URL;
const AUTH_API_PREFIX = `${API_BASE_PREFIX}auth/`;
const OPTIONS_API_PREFIX = `${API_BASE_PREFIX}options/`;
const AUTHENTICATED_API_PREFIX = `${API_BASE_PREFIX}authenticated/`;
const EVENT_API_PREFIX = `${AUTHENTICATED_API_PREFIX}event/`;
const TEMPLATE_API_PREFIX = `${AUTHENTICATED_API_PREFIX}template/`;
const GUESTS_API_PREFIX = `${AUTHENTICATED_API_PREFIX}guest/`;
const IMPORT_EXPORT_API_PREFIX = `${AUTHENTICATED_API_PREFIX}import-export/`;
const MESSAGE_TEMPLATE_API_PREFIX = `${AUTHENTICATED_API_PREFIX}message-template/`;
const EMAIL_TEMPLATE_API_PREFIX = `${MESSAGE_TEMPLATE_API_PREFIX}email/`;
const WHATSAPP_TEMPLATE_API_PREFIX = `${MESSAGE_TEMPLATE_API_PREFIX}whatsapp/`;
const CHECKIN_API_PREFIX = `${AUTHENTICATED_API_PREFIX}check-in/`;
const SEATING_CHART_API_PREFIX = `${AUTHENTICATED_API_PREFIX}seating-charts/`;
const REPORT_API_PREFIX = `${AUTHENTICATED_API_PREFIX}report/`;
const SEAT_MANAGEMENT_API_PREFIX = `${AUTHENTICATED_API_PREFIX}seat/`;
const DIETARY_MANAGEMENT_API_PREFIX = `${AUTHENTICATED_API_PREFIX}dietary/`;
const IMPORT_LOG_API_PREFIX = `${AUTHENTICATED_API_PREFIX}import-log/`;

// Auth Region API
export const LOGIN_API = `${AUTH_API_PREFIX}login`;
export const LOGOUT_API = `${AUTH_API_PREFIX}logout`;

// Authenticated Region API
// Event Region API
export const CURRENT_EVENT_LIST_API = `${EVENT_API_PREFIX}current`;
export const PAST_EVENT_LIST_API = `${EVENT_API_PREFIX}past`;
export const EVENT_CREATE_API = `${EVENT_API_PREFIX}create`;
export const GET_EVENT_INFO_API = `${EVENT_API_PREFIX}info`;
export const UPDATE_EVENT_INFO_API = `${EVENT_API_PREFIX}update`;
export const UPLOAD_TEMP_EVENT_IMAGE_API = `${EVENT_API_PREFIX}upload-temp-image`;

// Template Region API
export const GET_TEMPLATE_LIST_API = `${TEMPLATE_API_PREFIX}`;

// Guest Region API
export const GUEST_LIST_API = `${GUESTS_API_PREFIX}list`;
export const GUEST_SUMMARY_API = `${GUESTS_API_PREFIX}summary`;
export const GUEST_CREATE_API = `${GUESTS_API_PREFIX}create`;
export const GUEST_INFO_API = `${GUESTS_API_PREFIX}info`;
export const GUEST_GROUP_API = `${GUESTS_API_PREFIX}group`;
export const GUEST_UPDATE_API = `${GUESTS_API_PREFIX}update`;
export const GUEST_DELETE_API = `${GUESTS_API_PREFIX}delete`;
export const GUEST_REPORT_API = `${GUESTS_API_PREFIX}report`;

// Import Export Region API
export const UPLOAD_TEMP_GUEST_LIST_API = `${IMPORT_EXPORT_API_PREFIX}upload`;
export const FETCH_TEMP_GUEST_LIST_API = `${IMPORT_EXPORT_API_PREFIX}fetch`;
export const CONFIRM_IMPORT_API = `${IMPORT_EXPORT_API_PREFIX}confirm-guest-list`;
export const EXPORT_GUEST_API = `${IMPORT_EXPORT_API_PREFIX}export-guest-list`;
export const EXPORT_GUEST_LIST_TEMPLATE = `${IMPORT_EXPORT_API_PREFIX}template`;
export const EXPORT_GUEST_REPORT_API = `${IMPORT_EXPORT_API_PREFIX}export-guest-report`;
export const EXPORT_DIETARY_REPORT_API = `${IMPORT_EXPORT_API_PREFIX}export-dietary-report`;

// Message Template Region API
// Email
export const GET_EMAIL_TEMPLATE_LIST_API = `${EMAIL_TEMPLATE_API_PREFIX}list`;
export const CREATE_EMAIL_TEMPLATE_API = `${EMAIL_TEMPLATE_API_PREFIX}create`;
export const GET_EMAIL_TEMPLATE_DETAILS_API = `${EMAIL_TEMPLATE_API_PREFIX}details`;
export const UPDATE_EMAIL_TEMPLATE_API = `${EMAIL_TEMPLATE_API_PREFIX}update`;
export const GET_GUEST_COUNT_API = `${EMAIL_TEMPLATE_API_PREFIX}guest-count`;
export const SEND_EMAIL_API = `${EMAIL_TEMPLATE_API_PREFIX}send`;
// WhatsApp

//Seat Management Region API
export const GET_CHECK_IN_WIDGET_API = `${SEAT_MANAGEMENT_API_PREFIX}check-in-widget`;
export const GET_SEATS_WIDGET_API = `${SEAT_MANAGEMENT_API_PREFIX}seats-widget`;
export const GET_TABLE_DETAIL_LIST_API = `${SEAT_MANAGEMENT_API_PREFIX}table`;
export const CREATE_TABLE_API = `${SEAT_MANAGEMENT_API_PREFIX}create-table`;
export const GET_TABLE_GUESTS_API = `${SEAT_MANAGEMENT_API_PREFIX}table-guests`;
export const GET_TABLE_DETAILS_API = `${SEAT_MANAGEMENT_API_PREFIX}table-details`;
export const UPDATE_TABLE_API = `${SEAT_MANAGEMENT_API_PREFIX}update`;
export const DELETE_TABLE_API = `${SEAT_MANAGEMENT_API_PREFIX}delete`;
export const ASSIGN_GUEST_API = `${SEAT_MANAGEMENT_API_PREFIX}assign-guest`;
export const UNSEAT_GUEST_API = `${SEAT_MANAGEMENT_API_PREFIX}unseat-guest`;

// Check In Region API
export const GET_EVENT_DETAILS_API = `${CHECKIN_API_PREFIX}event`;
export const GET_GUESTS_API = `${CHECKIN_API_PREFIX}guests`;
export const GET_GUEST_OPTIONS_API = `${CHECKIN_API_PREFIX}guest-options`;
export const GET_GUEST_INFO_API = `${CHECKIN_API_PREFIX}guest-info`;
export const GET_GROUP_MEMBERS_API = `${CHECKIN_API_PREFIX}group-members`;
export const GET_OPEN_TABLES_API = `${CHECKIN_API_PREFIX}open-tables`;
export const CHECK_IN_API = `${CHECKIN_API_PREFIX}check-in`;
export const CHECK_OUT_API = `${CHECKIN_API_PREFIX}check-out`;
export const WALK_IN_API = `${CHECKIN_API_PREFIX}walk-in`;

// Report Region API
export const GET_REPORT_WIDGET_API = `${REPORT_API_PREFIX}widget`;
export const GET_GUEST_REPORT_API = `${REPORT_API_PREFIX}guests`;

// Seating Chart Region API
export const GET_SEATING_HEADER_API = `${SEATING_CHART_API_PREFIX}header-data`;
export const GET_TABLES_GUESTS_API = `${SEATING_CHART_API_PREFIX}tables`;
export const GET_UNSEATED_GUESTS_API = `${SEATING_CHART_API_PREFIX}unseated-guests`;
export const UPDATE_GUESTS_SEATS_API = `${SEATING_CHART_API_PREFIX}update`;

// Import Log Region API
export const GET_IMPORT_LOG_LIST_API = `${IMPORT_LOG_API_PREFIX}list`;
export const GET_IMPORT_LOG_DETAILS_API = `${IMPORT_LOG_API_PREFIX}details`;

// Dietary Management Region API
export const GET_DIETARY_WIDGET_API = `${DIETARY_MANAGEMENT_API_PREFIX}widget`;
export const GET_DIETARY_LIST_API = `${DIETARY_MANAGEMENT_API_PREFIX}list`;
export const CREATE_DIETARY_API = `${DIETARY_MANAGEMENT_API_PREFIX}create`;
export const GET_DIETARY_DETAILS_API = `${DIETARY_MANAGEMENT_API_PREFIX}details`;
export const UPDATE_DIETARY_API = `${DIETARY_MANAGEMENT_API_PREFIX}update`;
export const GET_DIETARY_GUESTS_API = `${DIETARY_MANAGEMENT_API_PREFIX}guests`;
export const REMOVE_GUEST_DIETARY_API = `${DIETARY_MANAGEMENT_API_PREFIX}remove`;
export const DELETE_DIETARY_API = `${DIETARY_MANAGEMENT_API_PREFIX}delete`;

// Options Region API
export const DIETARY_OPTIONS_API = `${OPTIONS_API_PREFIX}dietary`;
export const TABLE_OPTIONS_API = `${OPTIONS_API_PREFIX}table`;
export const NO_TABLE_GUEST_OPTIONS_API = `${OPTIONS_API_PREFIX}no-table-guest`;
export const EMAIL_GUEST_OPTIONS_API = `${OPTIONS_API_PREFIX}email-guest-list`;
